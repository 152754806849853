import { memo, useMemo } from 'react';
import { HiChip } from 'react-icons/hi';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';

import AgentFullCard from './components/AgentFullCard';
import BatteryAndLteSingalIcons from './components/BatteryAndLteSignalIcons';
import BrainBatteryLteSignalPopover from './components/BrainBatteryLteSignalPopover';
import DetailsPopupFooter from './components/DetailsPopupFooter';
import DetailsPopupHeader from './components/DetailsPopupHeader';
import PaperCard from './components/PaperCard';
import SectionInfo from './components/SectionInfo';
import SectionTitle from './components/SectionTitle';
import StatusBadge from './components/StatusBadge';
import VehicleAgentTabs from './components/VehicleAgentTabs';
import { DETAILS_POPUP_TYPE } from './config/types';
import useDetailsPopupContext from './context/useDetailsPopupContext';

const GUTTER = '8px';

const FixedDiv = styled.div<{ $hasAlert?: boolean }>`
  position: fixed;
  left: 0;
  top: ${theme.dimensions.navbarHeight}px;
  width: ${theme.dimensions.detailsPopupWidth}px;
  max-width: calc(100% - ${GUTTER} - ${GUTTER});
  margin: ${GUTTER};
  height: calc(
    100% - ${theme.dimensions.navbarHeight}px - ${GUTTER} - ${GUTTER} -
      ${(props) =>
        props.$hasAlert ? `${theme.dimensions.alertsBottomPopupCollapsedHeight}px` : '0px'}
  );
  z-index: ${theme.layers.base};
  overflow: auto;
  background: ${theme.colors.white};
  user-select: none;
  border-radius: 10px;
  border: 1px solid ${theme.colors.midGrey};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WrapperDiv = styled.div`
  padding: 12px 16px 0;
`;

const BrainHorizontalDiv = styled.div`
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
`;

const BatteryAndStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DetailsPopupContent = memo(() => {
  const { type, agent, vehicle } = useDetailsPopupContext();
  const { hasAlert } = useAlarmsContext();

  const brainStatus = useMemo(() => {
    if (agent?.isOffline) {
      return <span style={{ opacity: 0.5 }}>{i18n.t('brainStatus.offline')}</span>;
    }
    if (agent?.connectionLost) {
      return <span style={{ opacity: 0.5 }}>{i18n.t('brainStatus.connectionLost')}</span>;
    }
    if (
      typeof agent?.sensors?.battery?.value?.battery_level === 'number' &&
      agent?.sensors?.battery?.value?.battery_level < 20
    ) {
      return <span style={{ color: theme.colors.red }}>{i18n.t('brainStatus.lowBattery')}</span>;
    }
    return i18n.t('brainStatus.online');
  }, [agent?.connectionLost, agent?.isOffline, agent?.sensors?.battery?.value?.battery_level]);

  if (!agent && !vehicle) {
    return null;
  }

  const isTypeAgent = type === DETAILS_POPUP_TYPE.agent;
  const isTypeVehicle = type === DETAILS_POPUP_TYPE.vehicle;

  const allAgents = (isTypeAgent && agent ? [agent] : vehicle?.agents || []).filter((a) => !!a);
  const sortedAgents = sortAgentsByStatusAndName(allAgents);

  const activeAgent = isTypeAgent ? sortedAgents[0] : agent;

  const vehicleAgents =
    agent?.isOffline && activeAgent ? [activeAgent, ...sortedAgents] : sortedAgents;

  return (
    <FixedDiv
      $hasAlert={hasAlert}
      data-id={isTypeAgent ? 'agent-details-popup' : 'vehicle-details-popup'}
    >
      <div>
        <DetailsPopupHeader type={type} agent={agent} vehicle={vehicle} />
        <WrapperDiv>
          {isTypeVehicle && (
            <VehicleAgentTabs agents={vehicleAgents} activeAgentId={activeAgent?.id} />
          )}
          <BrainHorizontalDiv>
            <BrainBatteryLteSignalPopover
              agent={agent}
              showBrain
              showLteSignal={false}
              showBattery={false}
              placement="bottomLeft"
            >
              <SectionTitle
                style={{ marginBottom: 0 }}
                data-id="SectionTitle-Brain"
                title={<>Brain: {brainStatus}</>}
                icon={<HiChip color={theme.colors.darkBlue} size={23} />}
              />
            </BrainBatteryLteSignalPopover>
            <BatteryAndStatusDiv>
              <BrainBatteryLteSignalPopover
                agent={agent}
                showBrain={false}
                showLteSignal
                showBattery
                placement="bottom"
              >
                <BatteryAndLteSingalIcons agent={agent} />
              </BrainBatteryLteSignalPopover>
              <StatusBadge
                status={agent?.status ?? AGENT_STATUS.connectionLost}
                isOffline={!!agent?.isOffline}
                vehicle={vehicle}
              />
            </BatteryAndStatusDiv>
          </BrainHorizontalDiv>
          <PaperCard>
            <SectionInfo agent={agent} vehicle={vehicle} />
          </PaperCard>
          <AgentFullCard key={activeAgent?.id} agent={activeAgent} />
        </WrapperDiv>
      </div>
      <DetailsPopupFooter type={type} agent={agent} vehicle={vehicle} />
    </FixedDiv>
  );
});

DetailsPopupContent.displayName = 'DetailsPopupContent';

export default DetailsPopupContent;
