import { memo } from 'react';
import { Routes, Route } from 'react-router-dom';

import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import AgentsPage from '~/pages/AgentsPage';
import AlarmsPage from '~/pages/AlarmsPage';
import BackofficePage from '~/pages/BackofficePage';
import CompanySettingsPage from '~/pages/CompanySettingsPage';
import DashboardPage from '~/pages/DashboardPage';
import DeveloperToolsPage from '~/pages/DeveloperToolsPage';
import ForgotPasswordPage from '~/pages/ForgotPasswordPage';
import LoginPage from '~/pages/LoginPage';
import LogsPage from '~/pages/LogsPage';
import MapPage from '~/pages/MapPage';
import MFAPage from '~/pages/MFAPage';
import MyAccountPage from '~/pages/MyAccountPage';
import NewPasswordChallengePage from '~/pages/NewPasswordChallengePage';
import ReportsPage from '~/pages/ReportsPage';
import ResetPasswordPage from '~/pages/ResetPasswordPage';
import StatusPage from '~/pages/StatusPage';
import SupportPage from '~/pages/SupportPage';
import UserManualPage from '~/pages/UserManualPage';
import VerifyConfirmationCodePage from '~/pages/VerifyConfirmationCodePage';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

const AppRoutes = memo(() => {
  const { isAuthenticated } = useAuthenticationContext();

  return (
    <Routes>
      {/* Index Route */}
      <Route
        path={routes.index}
        element={
          isAuthenticated ? (
            <PrivateRoute element={<DashboardPage />} />
          ) : (
            <PublicRoute element={<LoginPage />} />
          )
        }
      />
      {/* Public Routes */}
      <Route path={routes.login} element={<PublicRoute element={<LoginPage />} />} />
      <Route path={routes.mfa()} element={<PublicRoute element={<MFAPage />} />} />
      <Route
        path={routes.newPasswordChallenge()}
        element={<PublicRoute element={<NewPasswordChallengePage />} />}
      />
      <Route
        path={routes.resetPassword()}
        element={<PublicRoute element={<ResetPasswordPage />} />}
      />
      <Route
        path={routes.verifyConfirmationCode}
        element={<PublicRoute element={<VerifyConfirmationCodePage />} />}
      />
      <Route
        path={routes.forgotPassword}
        element={<PublicRoute element={<ForgotPasswordPage />} />}
      />
      {/* Private Routes */}
      <Route path={routes.map()} element={<PrivateRoute element={<MapPage />} />} />
      <Route path={routes.dashboard} element={<PrivateRoute element={<DashboardPage />} />} />
      <Route path={routes.agents} element={<PrivateRoute element={<AgentsPage />} />} />
      <Route path={routes.status()} element={<PrivateRoute element={<StatusPage />} />} />
      <Route path={routes.alarms} element={<PrivateRoute element={<AlarmsPage />} />} />
      <Route path={routes.logs} element={<PrivateRoute element={<LogsPage />} />} />
      <Route path={routes.reports} element={<PrivateRoute element={<ReportsPage />} />} />
      <Route path={routes.myAccount()} element={<PrivateRoute element={<MyAccountPage />} />} />
      <Route
        path={routes.companySettings()}
        element={<PrivateRoute element={<CompanySettingsPage />} />}
      />
      <Route path={routes.backoffice()} element={<PrivateRoute element={<BackofficePage />} />} />
      <Route path={routes.userManual} element={<PrivateRoute element={<UserManualPage />} />} />
      <Route
        path={routes.developerTools}
        element={<PrivateRoute element={<DeveloperToolsPage />} />}
      />
      <Route path={routes.support} element={<PrivateRoute element={<SupportPage />} />} />
    </Routes>
  );
});

AppRoutes.displayName = 'AppRoutes';

export default AppRoutes;
