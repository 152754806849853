import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { ConfigProvider, Collapse } from 'antd';
import isBoolean from 'lodash/isBoolean';
import { memo } from 'react';
import styled from 'styled-components';

import useQueryAlarmConfigurationOverrides from '~/apollo/hooks/configurations/useQueryAlarmConfigurationOverrides';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';

import AlarmConfigurationItemList, { type Props } from './AlarmConfigurationItemList';

const Article = styled.article`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const H4 = styled.h4`
  margin: 0;
`;

const ScopeIdP = styled.p`
  margin: -4px 0 8px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
`;

const AlarmConfigurationDetails = memo(({ alarmConfiguration }: Props) => {
  const { isSuperAdmin } = useCurrentUserContext();

  const { alarmConfigurationOverrides, isLoading } = useQueryAlarmConfigurationOverrides({
    alarmConfigurationName: alarmConfiguration.name,
    skip: !isSuperAdmin,
  });

  return (
    <Article>
      <AlarmConfigurationItemList alarmConfiguration={alarmConfiguration} />
      <H4>
        {isLoading
          ? i18n.t('common.loading')
          : i18n.t('alarmConfigurations.configurationOverrides', {
              count: alarmConfigurationOverrides.length,
            })}
      </H4>
      {!isLoading && alarmConfigurationOverrides.length > 0 && (
        <div>
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  headerBg: 'rgba(0, 0, 0, 0.02)',
                },
              },
            }}
          >
            <Collapse
              defaultActiveKey={[]}
              expandIconPosition="start"
              items={alarmConfigurationOverrides.map((override) => ({
                key: JSON.stringify(override),
                label: (
                  <span>
                    <b>{override.scope.__typename}:</b> {override.scope.name || override.scope.id}
                    {isBoolean(override.activated) && (
                      <>
                        {' '}
                        <span
                          style={{
                            color: override.activated ? theme.colors.green : theme.colors.red,
                          }}
                        >
                          (
                          {override.activated
                            ? i18n.t('alarmConfigurations.active')
                            : i18n.t('alarmConfigurations.inactive')}
                          )
                        </span>
                      </>
                    )}
                  </span>
                ),
                children: (
                  <div>
                    <ScopeIdP>
                      <InfoCircleOutlined />
                      <b>{override.scope.__typename} ID:</b> {override.scope.id}
                    </ScopeIdP>
                    <AlarmConfigurationItemList alarmConfiguration={override} />
                  </div>
                ),
              }))}
            />
          </ConfigProvider>
        </div>
      )}
    </Article>
  );
});

AlarmConfigurationDetails.displayName = 'AlarmConfigurationDetails';

export default AlarmConfigurationDetails;
