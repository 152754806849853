import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Button } from 'antd';
import { memo } from 'react';

import AgentsTable from '~/components/AgentsTable';
import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SettingsTopFlexDiv } from '~/components/settings/shared';
import useAgentsContext from '~/context/useAgentsContext';
import useModalsContext from '~/context/useModalsContext';
import useInitialLoading from '~/hooks/useInitialLoading';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';

const AgentsPage = memo(() => {
  const { isInitialLoading } = useInitialLoading();
  const { agents, isLoading: isAgentsLoading } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();

  const { openModal } = useModalsContext();

  const isLoading = isInitialLoading || isAgentsLoading;

  return (
    <PageContentWrapper>
      <Container>
        <PageHeader
          title={i18n.t('agentsPage.title')}
          subtitle={i18n.t('agentsPage.subtitle')}
          style={{ paddingBottom: currentSubsidiary?.id ? 0 : undefined }}
        />
        {currentSubsidiary?.id && (
          <SettingsTopFlexDiv>
            <>
              <TabSectionHeader
                title={
                  isLoading
                    ? i18n.t('common.loading')
                    : `${i18n.t('common.agents')} (${agents.length})`
                }
              />
              <Button
                disabled={!currentSubsidiary?.id}
                loading={isLoading}
                size="middle"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  openModal({
                    type: 'agentAddEdit',
                    agentId: undefined,
                    refetchOnCompleted: async () => undefined,
                  });
                }}
                data-id="add-agent-btn"
              >
                {i18n.t('agentForm.addAgent')}
              </Button>
            </>
          </SettingsTopFlexDiv>
        )}
        <AgentsTable />
      </Container>
    </PageContentWrapper>
  );
});

AgentsPage.displayName = 'AgentsPage';

export default AgentsPage;
