import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import DotChartOutlined from '@ant-design/icons/DotChartOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import OneToOneOutlined from '@ant-design/icons/OneToOneOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { Button, Skeleton, Steps, Switch, Tooltip } from 'antd';
import { memo, useEffect, useMemo, useState, type ReactNode } from 'react';
import styled from 'styled-components';

import useQuerySubsidiaryLogs from '~/apollo/hooks/logs/useQuerySubsidiaryLogs';
import Container from '~/components/Container';
import LogCard from '~/components/LogCard';
import MissingCompanyFeature from '~/components/MissingCompanyFeature';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import TimeAgo from '~/components/TimeAgo';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import type { Log, LogType } from '~/types/log';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(380px, auto);
  gap: 1rem;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
    padding-bottom: 16px;
  }
`;

const StickySideFiltersDiv = styled.div`
  padding: 16px;
  background: ${theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${theme.colors.thinGrey};
  position: sticky;
  top: calc(${theme.dimensions.navbarHeight}px + 16px);

  ${theme.medias.lteSmall} {
    position: initial;
    top: 0;
  }
`;

const FilterH4 = styled.h4`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FiltersUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 16px;

  & > li {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
`;

const BottomDiv = styled.div`
  margin: 0;
`;

const NoResultsDiv = styled.div`
  background: ${theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  & > h5 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  & > p {
    font-size: 15px;
    margin: 0;
  }
`;

const LOG_GROUP_TYPES: Record<
  string,
  {
    icon: ReactNode;
    types: LogType[];
  }
> = {
  alarms: {
    icon: <ExclamationCircleOutlined />,
    types: ['ALARM_ADMINISTRATOR_ASSOCIATED', 'ALARM_ADMINISTRATOR_DISASSOCIATED'],
  },
  agents: {
    icon: <TeamOutlined />,
    types: ['CREATE_CARRIER', 'CARRIER_ATTRIBUTE_SET', 'CARRIER_ATTRIBUTES_SET', 'DELETE_CARRIER'],
  },
  devices: {
    icon: <OneToOneOutlined />,
    types: ['CARRIER_DEVICE_ASSOCIATED', 'CARRIER_DEVICE_DISASSOCIATED'],
  },
  videoStreams: {
    icon: <DotChartOutlined />,
    types: [
      'CARRIER_START_VIDEO_STREAM',
      'CARRIER_STOP_VIDEO_STREAM',
      'REQUEST_VIDEO_STREAM_STATUS',
    ],
  },
  subsidiary: {
    icon: <HomeOutlined />,
    types: [
      'CREATED',
      'SUBSIDIARY_UPDATED',
      'DELETED',
      'SUBSIDIARY_ADMIN_CREATED',
      'SUBSIDIARY_ADMIN_DELETED',
    ],
  },
};

const DEFAULT_LOG_TYPE_FILTERS: LogType[] = Object.values(LOG_GROUP_TYPES)
  .map(({ types }) => types)
  .flat();

const LogsPage = memo(() => {
  const { showLogsPageFeature } = useCompanyFeatures();
  const [cachedLogs, setCachedLogs] = useState<Log[]>([]);
  const [token, setToken] = useState<string | null>(null);

  const [logTypesFilter, setLogTypesFilter] =
    useState<(LogType | string)[]>(DEFAULT_LOG_TYPE_FILTERS);

  const hasLogs = cachedLogs.length > 0;

  const { currentSubsidiary } = useSubsidiary();

  const { logs, isLoading, nextToken } = useQuerySubsidiaryLogs({
    subsidiaryId: currentSubsidiary?.id || '',
    nextToken: token,
  });

  useEffect(() => {
    setCachedLogs((prev) => [...prev, ...logs]);
  }, [logs]);

  const filteredLogs = useMemo(
    () => cachedLogs.filter((log) => logTypesFilter.includes(log.data.type)),
    [cachedLogs, logTypesFilter],
  );

  if (!showLogsPageFeature) {
    return (
      <MissingCompanyFeature
        companyFeatureKey="logsPage"
        showFeature={showLogsPageFeature}
        isLoading={isLoading}
      />
    );
  }

  return (
    <PageContentWrapper>
      <Container>
        <PageHeader title={i18n.t('logsPage.title')} subtitle={i18n.t('logsPage.subtitle')} />
        {!currentSubsidiary?.id ? (
          <TabSectionHeader title={i18n.t('subsidiaryDetails.missingSubsidiary')} />
        ) : (
          <GridDiv>
            <div>
              {filteredLogs.length === 0 && !isLoading && (
                <NoResultsDiv>
                  <h5>
                    {hasLogs
                      ? i18n.t('logsPage.noFilteredResultsTitle')
                      : i18n.t('logsPage.noRawResultsTitle')}
                  </h5>
                  <p>
                    {hasLogs
                      ? i18n.t('logsPage.noFilteredResultsDescription')
                      : i18n.t('logsPage.noRawResultsDescription')}
                  </p>
                  {hasLogs && (
                    <Button
                      style={{ marginTop: '16px' }}
                      icon={<CloseCircleOutlined />}
                      type="primary"
                      onClick={() => {
                        setLogTypesFilter(DEFAULT_LOG_TYPE_FILTERS);
                      }}
                    >
                      {i18n.t('logsPage.resetFilters')}
                    </Button>
                  )}
                </NoResultsDiv>
              )}
              <Steps
                progressDot
                direction="vertical"
                current={Number.MAX_SAFE_INTEGER}
                items={[
                  ...filteredLogs.map((log) => ({
                    title: (
                      <Tooltip
                        placement="left"
                        title={new Date(log?.timestamp).toLocaleString(currentLanguage(), {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                        })}
                      >
                        <b style={{ cursor: 'help' }}>
                          <TimeAgo date={log?.timestamp} />
                        </b>
                      </Tooltip>
                    ),
                    description: <LogCard log={log} />,
                  })),
                  ...(nextToken && !isLoading
                    ? [
                        {
                          title: (
                            <div>
                              <Button
                                icon={<CloudDownloadOutlined />}
                                loading={isLoading}
                                onClick={() => {
                                  setToken(nextToken);
                                }}
                              >
                                {i18n.t('logsPage.loadMoreLogs')}
                              </Button>
                            </div>
                          ),
                          description: null,
                        },
                      ]
                    : []),
                ]}
              />
              {isLoading && <Skeleton active />}
            </div>
            <aside>
              <StickySideFiltersDiv>
                {Object.entries(LOG_GROUP_TYPES).map(([groupKey, group]) => (
                  <div key={groupKey}>
                    <FilterH4>
                      {group.icon}
                      <span>{i18n.t(`logsPage.filter.${groupKey}`)}</span>
                    </FilterH4>
                    <FiltersUl>
                      {group.types.map((type) => {
                        const count = cachedLogs.filter((log) => log.data.type === type).length;

                        return (
                          <li key={type}>
                            <Switch
                              checked={logTypesFilter.includes(type)}
                              loading={isLoading}
                              onChange={() => {
                                if (logTypesFilter.includes(type)) {
                                  setLogTypesFilter((prev) =>
                                    prev.filter((prevType) => prevType !== type),
                                  );
                                } else {
                                  setLogTypesFilter((prev) => [...prev, type]);
                                }
                              }}
                            />
                            {i18n.t(`general.logs.type.${type}`)}
                            {` (${count})`}
                          </li>
                        );
                      })}
                    </FiltersUl>
                  </div>
                ))}
                <BottomDiv>
                  <EyeOutlined />{' '}
                  {isLoading
                    ? i18n.t('logsPage.loadingLogs')
                    : i18n.t('logsPage.showingTheLastXLogs', {
                        logsRatio:
                          filteredLogs.length === cachedLogs.length
                            ? cachedLogs.length
                            : `${filteredLogs.length}/${cachedLogs.length}`,
                      })}
                </BottomDiv>
              </StickySideFiltersDiv>
            </aside>
          </GridDiv>
        )}
      </Container>
    </PageContentWrapper>
  );
});

LogsPage.displayName = 'LogsPage';

export default LogsPage;
