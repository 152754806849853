import MenuOutlined from '@ant-design/icons/MenuOutlined';
import { Button } from 'antd';
import { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

import NavbarContactsOnCall from '~/components/Navbar/components/NavbarContactsOnCall';
import NavbarHeader from '~/components/Navbar/components/NavbarHeader';
import NavbarUserDropdown from '~/components/Navbar/components/NavbarUserDropdown';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import theme from '~/theme';
import curateUrl from '~/utils/parse/curateUrl';

import NavbarMobileDrawer from './NavbarMobileDrawer';
import NavbarAlarmsLink from '../components/NavbarAlarmsLink';

const StyledNavbarHeader = styled(NavbarHeader)`
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between;
`;

const NavbarMobile = memo(() => {
  const { currentUser } = useCurrentUserContext();

  const hasLoaded = !!currentUser;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  return (
    <>
      <StyledNavbarHeader>
        <Button
          data-id="NavbarMobile-OpenDrawerButton"
          onClick={openDrawer}
          icon={<MenuOutlined style={{ color: theme.colors.white, fontSize: '15px' }} />}
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
          type="default"
          ghost
        >
          <img
            src={curateUrl('/images/logo-wearin-white.png')}
            alt="Wearin"
            style={{ width: '90px', transform: 'translateY(-1px)' }}
          />
        </Button>
        {hasLoaded && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <NavbarContactsOnCall />
            <NavbarAlarmsLink />
            <NavbarUserDropdown />
          </div>
        )}
      </StyledNavbarHeader>
      <NavbarMobileDrawer isOpen={isDrawerOpen} onClose={closeDrawer} />
    </>
  );
});

NavbarMobile.displayName = 'NavbarMobile';

export default NavbarMobile;
