import { gql } from '@apollo/client';

import type { IvsStream } from '~/types/videoStream';

export interface CarrierSubscriptionVariables {
  subsidiaryID: string;
}

export interface CarrierSubscriptionData {
  carrier: {
    carrier: {
      id: string;
      name: string;
      email: string | null;
      device: {
        name: string;
        attributes: {
          __typename?: 'Attribute';
          name: string;
          value: string;
        }[];
      };
      attributes: {
        __typename?: 'Attribute';
        name: string;
        value: string;
      }[];
      video_stream: IvsStream;
    };
  };
}

export default gql`
  subscription SubscribeToCarrier($subsidiaryID: ID!) {
    carrier(subsidiary_id: $subsidiaryID) {
      carrier {
        id
        name
        ... on Carrier_Cognito {
          email
        }
        device {
          name
          attributes {
            name
            value
          }
        }
        attributes {
          name
          value
        }
        video_stream {
          ivs_stream_state
        }
      }
    }
  }
`;
