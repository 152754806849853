import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import { Badge, Button, Modal } from 'antd';
import { memo, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';

import EditorialContent from '~/components/EditorialContent';
import LoadingSpinner from '~/components/LoadingSpinner';
import MarkdownContent from '~/components/MarkdownContent';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n, { currentLanguage } from '~/locales/i18n';
import useHighlightedFeatures, {
  getHighlightedItems,
  type HighlightedFeatureId,
} from '~/store/useHighlightedFeatures';
import theme from '~/theme';
import type { LOCALE_CODE } from '~/types/locale';
import type { ModalProps } from '~/types/modal';
import logger from '~/utils/logger';

const BORDER = '1px solid #e8e8e8';
const MODAL_CONTENT_HEIGHT = '600px';

const StyledModal = styled(Modal)`
  max-width: 100%;

  & > div.ant-modal-content {
    padding: 0;
    overflow: hidden;

    & > div.ant-modal-header {
      padding: 16px 16px;
      margin: 0;
      border-bottom: ${BORDER};
    }
  }
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 200px) minmax(0, 1fr);
  height: ${MODAL_CONTENT_HEIGHT};
  overflow: hidden;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 150px) minmax(0, 1fr);
  }
`;

const LeftDiv = styled.div`
  height: ${MODAL_CONTENT_HEIGHT};
  overflow: hidden;
  border-right: ${BORDER};
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  height: ${MODAL_CONTENT_HEIGHT};
`;

const Li = styled.li`
  & > span {
    display: inline-block;
    width: 100%;
  }
`;

const ItemButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.$active ? theme.colors.primaryBlue : 'transparent')};
  color: ${(props) => (props.$active ? theme.colors.white : theme.colors.black)};
  padding: 16px;
  border: none;
  border-left: ${BORDER};
  border-bottom: ${BORDER};
  font-size: 16px;
  text-align: left;
  width: 100%;

  &:hover {
    background: ${(props) => (props.$active ? theme.colors.primaryBlue : theme.colors.lightGrey)};
  }
`;

const ContentDiv = styled.div`
  padding: 12px 12px 0;
  height: 600px;
  max-height: 100%;
  overflow-y: auto;
  height: ${MODAL_CONTENT_HEIGHT};
`;

const TitleH2 = styled.h2`
  margin: 0 0 16px;
`;

const HighlightedFeaturesModal = memo(({ isOpen, onClose }: ModalProps) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures } = useCompanyFeatures();

  const highlightedFeaturesItems = useMemo(
    () => getHighlightedItems({ companyFeatures, isSuperAdmin }),
    [companyFeatures, isSuperAdmin],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<HighlightedFeatureId>(
    highlightedFeaturesItems[0].id,
  );
  const [markdownItems, setMarkdownItems] = useState<Partial<Record<HighlightedFeatureId, string>>>(
    {},
  );

  const isCurrentItemActive = highlightedFeaturesItems.some(
    (item) => item.active && item.id === currentItem,
  );

  const markdownContent = markdownItems[currentItem];

  const acknowledgedHighlightedFeatures = useHighlightedFeatures(
    (state) => state.acknowledgedHighlightedFeatures,
  );
  const acknowledgeHighlightedFeature = useHighlightedFeatures(
    (state) => state.acknowledgeHighlightedFeature,
  );
  const unacknowledgeHighlightedFeature = useHighlightedFeatures(
    (state) => state.unacknowledgeHighlightedFeature,
  );

  const hasBeenAcknowledged = acknowledgedHighlightedFeatures.includes(currentItem);

  const fetchMarkdownItem = useCallback(
    async (
      item: HighlightedFeatureId,
    ): Promise<{ item: HighlightedFeatureId; markdown: string | undefined }> => {
      try {
        const fileName: `${HighlightedFeatureId}.${LOCALE_CODE}.md` = `${item}.${currentLanguage()}.md`;
        const result = await import(`~/locales/content/highlightedFeatures/${fileName}`);
        const response = await fetch(result.default);
        const markdown = await response.text();

        return { item, markdown };
      } catch (error) {
        logger.error('HighlightedFeaturesModal: fetchMarkdownItem error', { error });

        return { item, markdown: undefined };
      }
    },
    [],
  );

  useEffect(() => {
    setCurrentItem(highlightedFeaturesItems[0].id);
  }, [highlightedFeaturesItems]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const items = await Promise.all(
        highlightedFeaturesItems.map((item) => fetchMarkdownItem(item.id)),
      );

      setMarkdownItems(items.reduce((acc, item) => ({ ...acc, [item.item]: item.markdown }), {}));
      setIsLoading(false);
    })();
  }, [highlightedFeaturesItems, fetchMarkdownItem]);

  const menuListRef = useRef<HTMLUListElement>(null);
  const contentDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentDivRef.current?.scrollTo(0, 0);

    if (isOpen) {
      logger.log('HighlightedFeaturesModal: opened item', { currentItem });
    }
  }, [isOpen, currentItem]);

  useEffect(() => {
    menuListRef.current?.scrollTo(0, 0);
    contentDivRef.current?.scrollTo(0, 0);
  }, [isOpen]);

  return (
    <StyledModal
      title={i18n.t('highlightedFeatures.title')}
      footer={null}
      centered
      width={1200}
      open={isOpen}
      onCancel={onClose}
    >
      <GridDiv>
        <LeftDiv>
          <Ul ref={menuListRef} data-id="highlighted-feature-list">
            {highlightedFeaturesItems.map((item) => (
              <Li key={item.id}>
                <Badge
                  count={item.active && !acknowledgedHighlightedFeatures.includes(item.id) ? 1 : 0}
                  offset={[-12, 12]}
                  style={{ boxShadow: 'none' }}
                  size="small"
                >
                  <ItemButton
                    $active={currentItem === item.id}
                    onClick={() => {
                      setCurrentItem(item.id);
                    }}
                  >
                    {i18n.t(`highlightedFeatures.items.${item.id}`)}
                  </ItemButton>
                </Badge>
              </Li>
            ))}
          </Ul>
        </LeftDiv>
        <ContentDiv ref={contentDivRef}>
          <TitleH2 data-id="highlighted-feature-title">
            {i18n.t(`highlightedFeatures.items.${currentItem}`)}
          </TitleH2>
          {isLoading ? (
            <LoadingSpinner style={{ marginTop: '16px' }} />
          ) : (
            <>
              {isCurrentItemActive && (
                <Button
                  type={hasBeenAcknowledged ? 'default' : 'primary'}
                  data-id="highlighted-feature-mark-as-btn"
                  icon={hasBeenAcknowledged ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  onClick={() => {
                    if (hasBeenAcknowledged) {
                      unacknowledgeHighlightedFeature(currentItem);
                      logger.log('HighlightedFeaturesModal: mark item as unread', { currentItem });
                    } else {
                      acknowledgeHighlightedFeature(currentItem);
                      logger.log('HighlightedFeaturesModal: mark item as read', { currentItem });
                    }
                  }}
                >
                  {hasBeenAcknowledged
                    ? i18n.t('highlightedFeatures.markAsUnread')
                    : i18n.t('highlightedFeatures.markAsRead')}
                </Button>
              )}
              <EditorialContent>
                <MarkdownContent markdown={markdownContent} />
              </EditorialContent>
            </>
          )}
        </ContentDiv>
      </GridDiv>
    </StyledModal>
  );
});

HighlightedFeaturesModal.displayName = 'HighlightedFeaturesModal';

export default HighlightedFeaturesModal;
