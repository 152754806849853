import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import QUERY_SUBSIDIARY_LOGS, {
  type SubsidiaryCarrierListQueryData,
  type SubsidiaryLogsQueryVariables,
} from '~/apollo/operations/queries/QuerySubsidiaryLogs';
import type { Log } from '~/types/log';

export default function useQuerySubsidiaryLogs({
  subsidiaryId,
  nextToken,
}: {
  subsidiaryId: string;
  nextToken: string | null;
}): {
  logs: Log[];
  nextToken: string | null;
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<
    SubsidiaryCarrierListQueryData,
    SubsidiaryLogsQueryVariables
  >(QUERY_SUBSIDIARY_LOGS, {
    variables: {
      subsidiaryId,
      nextToken,
    },
    skip: !subsidiaryId,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  return useMemo(
    () => ({
      logs: (data?.subsidiary?.logs?.items || []).map((item) => {
        const itemData = JSON.parse(item.data);
        return {
          ...item,
          type: itemData.type,
          data: itemData,
        };
      }),
      nextToken: data?.subsidiary?.logs?.nextToken || null,
      isLoading: loading || !subsidiaryId,
      error,
    }),
    [
      data?.subsidiary?.logs?.items,
      data?.subsidiary?.logs?.nextToken,
      loading,
      subsidiaryId,
      error,
    ],
  );
}
