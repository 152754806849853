import { useQuery, type ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import QUERY_ALARM_CONFIGURATIONS, {
  type AlarmConfigurationsQueryData,
  type AlarmConfigurationsQueryVariables,
} from '~/apollo/operations/queries/QueryAlarmConfigurations';
import type { AlarmConfiguration } from '~/types/configuration';

export default function useQueryAlarmConfigurations({ skip = false }: { skip: boolean }): {
  alarmConfigurations: AlarmConfiguration[];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<
    AlarmConfigurationsQueryData,
    AlarmConfigurationsQueryVariables
  >(QUERY_ALARM_CONFIGURATIONS, {
    variables: {
      nextToken: null,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  return useMemo(
    () => ({
      alarmConfigurations: orderBy(data?.configurations?.items || [], ['name'], ['asc']),
      isLoading: loading,
      error,
    }),
    [data?.configurations?.items, loading, error],
  );
}
