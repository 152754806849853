import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import { Badge, Tooltip } from 'antd';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import routes from '~/config/routes';
import useModalsContext from '~/context/useModalsContext';
import useCompanyEmergencyContacts from '~/hooks/useCompanyEmergencyContacts';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';

export const circleButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 100%;
  font-size: 20px;
  cursor: pointer;
  color: ${theme.colors.white};

  &:hover,
  &:focus {
    color: ${theme.colors.white};
  }
`;

const Button = styled.button`
  ${circleButtonStyles}
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background: transparent;
  white-space: nowrap;
  gap: 8px;
`;

const NavbarContactsOnCall = memo(() => {
  const navigate = useNavigate();
  const { companyFeatures } = useCompanyFeatures();
  const { openModal } = useModalsContext();
  const { onCallPhoneNumbers, emergencyContactsList } = useCompanyEmergencyContacts();

  if (!companyFeatures.contactsOnCall) {
    return null;
  }

  return (
    <Tooltip title={i18n.t('header.menu.contactsOnCall')}>
      <Badge
        count={onCallPhoneNumbers.length}
        offset={[0, 0]}
        style={{ boxShadow: 'none' }}
        size="small"
        color={onCallPhoneNumbers.length > 0 ? 'green' : 'red'}
        showZero
      >
        <Button
          onClick={() => {
            if (!emergencyContactsList.length) {
              navigate(routes.companySettings({ tab: 'emergency-contacts' }));
            } else {
              openModal({ type: 'emergencyContactsOnCall' });
            }
          }}
        >
          <PhoneOutlined />
        </Button>
      </Badge>
    </Tooltip>
  );
});
NavbarContactsOnCall.displayName = 'NavbarContactsOnCall';

export default NavbarContactsOnCall;
