import { useMemo } from 'react';

import type { SortableSearchableColumn } from '~/components/SortableSearchableTable';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import { SORT_ORDER } from '~/types/table';

import createAgentStatusSorter from '../utils/createAgentStatusSorter';
import createDefaultSorter from '../utils/createDefaultSorter';

export default function useAgentsTableColumns() {
  const { companyFeatures } = useCompanyFeatures();

  const columns: SortableSearchableColumn[] = useMemo(
    () => [
      {
        key: 'agent',
        dataIndex: 'agent',
        searchIndex: 'agent',
        title: i18n.t('agentsPage.agent'),
        filtered: true,
        hasFilterHighlight: true,
        popover: 'agentPopover',
        sorter: createDefaultSorter('agent'),
        onCell: () => ({ style: { fontWeight: 600 }, 'data-id': 'agent-name' }),
      },
      {
        key: 'team',
        dataIndex: 'team',
        searchIndex: 'team',
        title: i18n.t('agentsPage.team'),
        filtered: true,
        hasFilterHighlight: true,
        sorter: createDefaultSorter('team'),
      },
      {
        key: 'agentStatus',
        dataIndex: 'agentStatus',
        searchIndex: 'agentStatusText',
        title: i18n.t('agentsPage.status'),
        filtered: true,
        hasFilterHighlight: false,
        sorter: createAgentStatusSorter(),
        defaultSortOrder: SORT_ORDER.descend,
      },
      ...(companyFeatures.vehicles
        ? [
            {
              key: 'vehicle',
              dataIndex: 'vehicle',
              searchIndex: 'vehicle',
              title: i18n.t('agentsPage.vehicle'),
              filtered: true,
              hasFilterHighlight: true,
              sorter: createDefaultSorter('vehicle'),
            },
          ]
        : []),
      {
        key: 'garment',
        dataIndex: 'garment',
        searchIndex: 'garment',
        title: i18n.t('agentsPage.garment'),
        filtered: true,
        hasFilterHighlight: true,
        sorter: createDefaultSorter('garment'),
      },
      {
        key: 'garmentStatus',
        dataIndex: 'garmentStatus',
        searchIndex: 'garmentStatus',
        title: i18n.t('agentsPage.garmentStatus'),
        filtered: true,
        hasFilterHighlight: true,
        popover: 'garmentStatusPopover',
        sorter: createDefaultSorter('garmentStatus'),
        onCell: ({ garmentStatusColor }: { garmentStatusColor?: string }) => ({
          style: { color: garmentStatusColor },
          'data-id': 'garment-status',
        }),
      },
      {
        key: 'missionStart',
        dataIndex: 'missionStart',
        searchIndex: 'missionStart',
        title: i18n.t('agentsPage.missionStart'),
        sorter: createDefaultSorter('missionStart'),
      },
      {
        key: 'missionEnd',
        dataIndex: 'missionEnd',
        searchIndex: 'missionEnd',
        title: i18n.t('agentsPage.missionEnd'),
        sorter: createDefaultSorter('missionEnd'),
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        searchIndex: 'actions',
        title: i18n.t('common.actions'),
      },
    ],
    [companyFeatures.vehicles],
  );

  return useMemo(() => columns, [columns]);
}
