import { gql, useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

export interface MergedAdmin {
  __typename: 'CompanyAdmin' | 'SubsidiaryAdmin';
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  subsidiaryName: string | null;
}

interface QueryCompanyAdminsInput {
  companyId: string;
}

interface QueryCompanyAdminsOutput {
  company: {
    __typename: 'Company';
    admins: {
      __typename: 'AdministratorConnection';
      items: {
        __typename: 'CompanyAdmin';
        id: string;
        email: string;
        first_name: string | null;
        last_name: string | null;
      }[];
    };
    subsidiaries: {
      __typename: 'SubsidiaryConnection';
      items: {
        __typename: 'Subsidiary';
        id: string;
        name: string;
        admins: {
          __typename: 'AdministratorConnection';
          items: {
            __typename: 'SubsidiaryAdmin';
            id: string;
            email: string;
            first_name: string | null;
            last_name: string | null;
          }[];
        };
      }[];
    };
  };
}

export default function useQueryCompanyAdmins({ companyId }: { companyId: string | undefined }) {
  const { data, loading, error, refetch } = useQuery<
    QueryCompanyAdminsOutput,
    QueryCompanyAdminsInput
  >(
    gql`
      query QueryAdmins($companyId: ID!) {
        company(id: $companyId) {
          admins(limit: 1000) {
            items {
              id
              email
              first_name
              last_name
            }
          }
          subsidiaries(limit: 1000) {
            items {
              id
              name
              admins(limit: 1000) {
                items {
                  id
                  email
                  first_name
                  last_name
                }
              }
            }
          }
        }
      }
    `,
    {
      skip: !companyId,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        companyId: companyId || '',
      },
    },
  );

  const companyAdmins: MergedAdmin[] = useMemo(
    () =>
      (data?.company?.admins?.items || [])?.map((admin) => ({
        ...admin,
        subsidiaryName: null,
      })),
    [data?.company?.admins?.items],
  );

  const subsidiaryAdmins: MergedAdmin[] = useMemo(() => {
    const admins: MergedAdmin[] = [];
    (data?.company?.subsidiaries?.items || []).forEach((subsidiary) => {
      subsidiary.admins.items.forEach((admin) => {
        admins.push({
          ...admin,
          subsidiaryName: subsidiary.name,
        });
      });
    });
    return admins;
  }, [data?.company?.subsidiaries?.items]);

  return useMemo(
    () => ({
      admins: orderBy([...companyAdmins, ...subsidiaryAdmins], ['email'], ['asc']),
      isLoading: loading,
      error,
      refetchCompanyAdmins: refetch,
    }),
    [companyAdmins, subsidiaryAdmins, loading, error, refetch],
  );
}
