import { useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_CREATE_AGENT, {
  type CreateAgentNonCognitoMutationVariables,
  type CreateAgentNonCognitoMutationData,
} from '~/apollo/operations/mutations/MutationCreateAgentNonCognito';

export default function useMutationCreateAgentNonCognito(): {
  createAgentNonCognito: (params: {
    variables: CreateAgentNonCognitoMutationVariables;
  }) => Promise<unknown>;
  isLoading: boolean;
  error?: ApolloError;
  createdAgentNonCognito?: CreateAgentNonCognitoMutationData['createNonCognitoCarrier'];
} {
  const [createAgentNonCognito, { loading, error, data }] = useMutation<
    CreateAgentNonCognitoMutationData,
    CreateAgentNonCognitoMutationVariables
  >(MUTATION_CREATE_AGENT);

  return useMemo(
    () => ({
      createAgentNonCognito,
      isLoading: loading,
      error,
      createdAgentNonCognito: data?.createNonCognitoCarrier,
    }),
    [createAgentNonCognito, data?.createNonCognitoCarrier, error, loading],
  );
}
