import { Space } from 'antd';
import { memo } from 'react';
import { FiMapPin } from 'react-icons/fi';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { GpsSensorType } from '~/types/sensor';

import LabelWithValue from './LabelWithValue';

interface Props {
  address: string | undefined;
  gps: GpsSensorType | null | undefined;
}

const InfoLocation = memo(({ address, gps }: Props) => {
  const hasData = !!address || (!!gps?.lat && !!gps?.lng);

  return (
    <Space align="center">
      <FiMapPin color={theme.colors.darkBlue} size={22} />
      <LabelWithValue
        data-id="DetailsPopup-InfoLocation"
        label={i18n.t('common.location')}
        value={
          hasData
            ? address || `${gps?.lat}, ${gps?.lng}`
            : i18n.t<string>('carrierDetailsPopup.locationNotFound')
        }
        hasCopyToClipboard={hasData}
      />
    </Space>
  );
});

InfoLocation.displayName = 'InfoLocation';

export default InfoLocation;
