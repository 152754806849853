import { useMemo } from 'react';

import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import { ALARM_TYPE } from '~/types/alarm';
import getEquipmentStatusLabel from '~/utils/equipment/getEquipmentStatusLabel';

import ContentGasSensors from '../components/ContentGasSensors';
import ContentHeartRate from '../components/ContentHeartRate';
import ContentImpactDetection from '../components/ContentImpactDetection';
import ContentPhysiologicalTemperature from '../components/ContentPhysiologicalTemperature';
import ContentRadiation from '../components/ContentRadiation';
import type { EquipmentItem } from '../components/EquipmentItemCard';

export default function useAgentEquipment(agent: Agent | undefined): {
  equipmentItems: EquipmentItem[];
  hasImpactDetectionAlarm: boolean;
} {
  const { companyFeatures } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const hasImpactDetectionAlarm = useMemo(
    () =>
      (agentOngoingAlarms || []).some((alarm) =>
        [ALARM_TYPE.traak_front, ALARM_TYPE.traak_back].includes(alarm.type),
      ),
    [agentOngoingAlarms],
  );

  const hasGasAlarm = useMemo(
    () =>
      (agentOngoingAlarms || []).some((alarm) =>
        [
          ALARM_TYPE.gasCH4HC,
          ALARM_TYPE.gasCO,
          ALARM_TYPE.gasCO2,
          ALARM_TYPE.gasH2S,
          ALARM_TYPE.gasO2,
        ].includes(alarm.type),
      ),
    [agentOngoingAlarms],
  );

  const equipmentItems: EquipmentItem[] = useMemo(
    () => [
      ...(companyFeatures.emergencyButton
        ? [
            {
              key: 'emergencyButton',
              label: i18n.t('carrierDetailsPopup.equipment.sosExternalButton'),
              isHealthy: !!agent?.equipmentStatus?.emergencyButton?.healthy,
              errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.emergencyButton),
              content: null,
              isOpen: false,
            },
          ]
        : []),
      ...(companyFeatures.heartRateSensor
        ? [
            {
              key: 'heartRateSensor',
              label: i18n.t('carrierDetailsPopup.equipment.heartRate'),
              isHealthy: !!agent?.equipmentStatus?.heartRate?.healthy,
              errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.heartRate),
              content: companyFeatures.biometricData ? <ContentHeartRate agent={agent} /> : null,
              isOpen: false,
            },
          ]
        : []),
      ...(companyFeatures.physiologicalTemperatureSensor || companyFeatures.bodyTemperatureSensor
        ? [
            {
              key: 'physiologicalTemperatureSensor',
              label: i18n.t('carrierDetailsPopup.equipment.physiologicalTemperature'),
              isHealthy:
                (companyFeatures.physiologicalTemperatureSensor
                  ? !!agent?.equipmentStatus?.physiologicalTemperature?.healthy
                  : true) &&
                (companyFeatures.bodyTemperatureSensor
                  ? !!agent?.equipmentStatus?.bodyTemperature?.healthy
                  : true),
              errorTooltip:
                (companyFeatures.physiologicalTemperatureSensor
                  ? getEquipmentStatusLabel(agent?.equipmentStatus?.physiologicalTemperature)
                  : undefined) ||
                (companyFeatures.bodyTemperatureSensor
                  ? getEquipmentStatusLabel(agent?.equipmentStatus?.bodyTemperature)
                  : undefined),
              content: companyFeatures.biometricData ? (
                <ContentPhysiologicalTemperature agent={agent} />
              ) : null,
              isOpen: false,
            },
          ]
        : []),
      ...(companyFeatures.gasSensor
        ? [
            {
              key: 'gasSensors',
              label: i18n.t('carrierDetailsPopup.equipment.gasSensors'),
              isHealthy: !!agent?.equipmentStatus?.gas?.healthy,
              errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.gas),
              content: <ContentGasSensors agent={agent} />,
              isOpen: hasGasAlarm,
            },
          ]
        : []),
      ...(companyFeatures.radiationSensor
        ? [
            {
              key: 'radiationSensors',
              label: i18n.t('carrierDetailsPopup.equipment.radiation'),
              isHealthy: !!agent?.equipmentStatus?.radiation?.healthy,
              errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.radiation),
              content: <ContentRadiation agent={agent} />,
              isOpen: hasGasAlarm,
            },
          ]
        : []),
      ...(companyFeatures.impactDetectionFront || companyFeatures.impactDetectionBack
        ? [
            {
              key: 'impactDetection',
              label: i18n.t('carrierDetailsPopup.equipment.impactDetection'),
              isHealthy:
                (companyFeatures.impactDetectionFront
                  ? !!agent?.equipmentStatus?.traakFront?.healthy
                  : true) &&
                (companyFeatures.impactDetectionBack
                  ? !!agent?.equipmentStatus?.traakBack?.healthy
                  : true),
              errorTooltip:
                (companyFeatures.impactDetectionFront
                  ? getEquipmentStatusLabel(agent?.equipmentStatus?.traakFront)
                  : undefined) ||
                (companyFeatures.impactDetectionBack
                  ? getEquipmentStatusLabel(agent?.equipmentStatus?.traakBack)
                  : undefined),
              content: <ContentImpactDetection agent={agent} />,
              isOpen: hasImpactDetectionAlarm,
            },
          ]
        : []),
    ],
    [
      agent,
      companyFeatures.biometricData,
      companyFeatures.bodyTemperatureSensor,
      companyFeatures.emergencyButton,
      companyFeatures.gasSensor,
      companyFeatures.radiationSensor,
      companyFeatures.heartRateSensor,
      companyFeatures.impactDetectionBack,
      companyFeatures.impactDetectionFront,
      companyFeatures.physiologicalTemperatureSensor,
      hasGasAlarm,
      hasImpactDetectionAlarm,
    ],
  );

  return useMemo(
    () => ({
      equipmentItems: agent ? equipmentItems : [],
      hasImpactDetectionAlarm,
    }),
    [agent, equipmentItems, hasImpactDetectionAlarm],
  );
}
