import PauseCircleOutlined from '@ant-design/icons/PauseCircleOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import * as IVSPlayer from 'amazon-ivs-player';
import { Button, Tooltip } from 'antd';
import { memo, useState, useEffect, useRef, type MouseEvent, type RefObject } from 'react';
import styled from 'styled-components';

import useMutationRequestVideoStreamStatus from '~/apollo/hooks/videoStream/useMutationRequestVideoStreamStatus';
import useQueryWithSubscriptionCarrierVideoStream from '~/apollo/hooks/videoStream/useQueryWithSubscriptionCarrierVideoStream';
import Text from '~/components/Text';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { STREAM_STATE, STREAM_REQUESTED_STATUS, type IvsStream } from '~/types/videoStream';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';
import getYesOrNo from '~/utils/parse/getYesOrNo';
import getVideoStreamLogId from '~/utils/video/getVideoStreamLogId';

const GUTTER = '8px';
const VIDEO_PLAYER_BACKGROUND_COLOR = '#333333';

const VideoWrapperDiv = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const PositionRelativeDiv = styled.div`
  position: relative;
  display: block;
`;

const StyledText = styled(Text)`
  height: ${theme.dimensions.videoStreamPlayerHeight}px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

const VideoPopupDiv = styled.div`
  top: ${GUTTER};
  left: ${GUTTER};
  color: ${theme.colors.darkGrey};
  width: calc(100% - ${GUTTER} - ${GUTTER});
  padding: 12px;
  position: absolute;
  border-radius: 8px;
  pointer-events: none;
  background-color: ${theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const BottomDiv = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  grid-gap: 8px;
`;

const ActionsDiv = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  grid-gap: 8px;
  align-items: center;
`;

const MAXIMUM_RELOAD_ATTEMPTS = 40;
const DEFAULT_PLAYER_AUTOPLAY = true;
const DEFAULT_PLAYER_MUTED = true;
const DEFAULT_PLAYER_VOLUME = 1;
const DEFAULT_REBUFFER_TO_LIVE = true;

function useLoadIvsPlayer(videoStream?: IvsStream): {
  videoElement: RefObject<HTMLVideoElement>;
} {
  const videoElement = useRef<HTMLVideoElement>(null);
  const player = useRef<IVSPlayer.MediaPlayer | null>(null);
  const reloadAttempts = useRef<number>(0);

  const streamUrl = `${videoStream?.playback_url}?token=${videoStream?.playback_key}`;

  useEffect(() => {
    const isStreamReady =
      videoStream?.ivs_stream_state === STREAM_STATE.START &&
      videoStream?.playback_url &&
      videoStream?.playback_key;

    logger.log('useLoadIvsPlayer: useEffect create', {
      videoStream,
      isStreamReady,
      videoStreamLogId: getVideoStreamLogId(videoStream?.playback_url),
      hasIvsPlayer: !!IVSPlayer,
      hasPlayer: !!player.current,
    });

    if (!isStreamReady || !IVSPlayer || player.current) {
      return undefined;
    }

    // First, check if the browser supports the Amazon IVS player.
    if (!IVSPlayer.isPlayerSupported) {
      notification.warning({
        message: 'Player not supported',
        description: 'The current browser does not support the Amazon IVS player.',
      });

      return undefined;
    }

    // Initialize player
    player.current = IVSPlayer.create({
      wasmBinary: curateUrl('/libs/amazon-ivs-player/amazon-ivs-wasmworker.min.wasm'),
      wasmWorker: curateUrl('/libs/amazon-ivs-player/amazon-ivs-wasmworker.min.js'),
    });

    if (videoElement.current) {
      player.current.attachHTMLVideoElement(videoElement.current);
    }

    const handleErrorEvent = (error: IVSPlayer.PlayerError) => {
      logger.log('useLoadIvsPlayer: handleErrorEvent', {
        videoStream,
        reloadAttempts: reloadAttempts.current,
        error,
      });

      if (reloadAttempts.current < MAXIMUM_RELOAD_ATTEMPTS) {
        reloadAttempts.current += 1;
        setTimeout(() => {
          player.current?.load(streamUrl);
        }, 1000);
      } else {
        notification.error({
          message: 'Player error',
          description: error.message,
        });
      }
    };

    // Attach event listeners
    player.current.addEventListener(IVSPlayer.PlayerEventType.ERROR, handleErrorEvent);
    // Setup stream default settings
    player.current.setRebufferToLive(DEFAULT_REBUFFER_TO_LIVE);
    player.current.setAutoplay(DEFAULT_PLAYER_AUTOPLAY);
    player.current.setVolume(DEFAULT_PLAYER_VOLUME);
    player.current.setMuted(DEFAULT_PLAYER_MUTED);
    // Debugging tool
    // player.current.setLogLevel(IVSPlayer.LogLevel.DEBUG);
    // Attempt to load the stream
    player.current.load(streamUrl);

    // Unsubscribing on component unload
    return () => {
      logger.log('useLoadIvsPlayer: useEffect destroy', {
        videoStream,
        videoStreamLogId: getVideoStreamLogId(videoStream?.playback_url),
        hasIvsPlayer: !!IVSPlayer,
        hasPlayer: !!player.current,
      });

      player.current?.removeEventListener(IVSPlayer.PlayerEventType.ERROR, handleErrorEvent);
      player.current?.delete();
      player.current = null;
    };
  }, [videoStream, streamUrl]);

  return { videoElement };
}

interface Props {
  agent: Agent | undefined;
}

const VideoStreamPlayer = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  const {
    videoStream,
    error: videoStreamError,
    isLoading: isVideoStreamLoading,
  } = useQueryWithSubscriptionCarrierVideoStream({
    agent,
  });

  const {
    requestVideoStreamStatus,
    error: requestError,
    requestStatus: requestStatusFromMutation,
    isLoading: isRequestLoading,
  } = useMutationRequestVideoStreamStatus();

  const { ongoingAlarms } = useAlarmsContext();

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [isStartLoading, setIsStartLoading] = useState<boolean>(false);
  const [isStopLoading, setIsStopLoading] = useState<boolean>(false);

  const { videoElement } = useLoadIvsPlayer(videoStream);

  const handleMouseOut = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsPopupVisible(false);
  };

  const handleMouseOver = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsPopupVisible(true);
  };

  const streamUrl = `${videoStream?.playback_url}?token=${videoStream?.playback_key}`;

  const requestStatus = requestStatusFromMutation || agent?.requested_video_stream_status;

  const isVideoSteamRunning = videoStream?.ivs_stream_state === STREAM_STATE.START;

  useEffect(() => {
    if (
      isStartLoading &&
      requestStatus === STREAM_REQUESTED_STATUS.STARTED &&
      videoStream?.ivs_stream_state === STREAM_STATE.START
    ) {
      setIsStartLoading(false);
    }
    if (
      isStopLoading &&
      requestStatus === STREAM_REQUESTED_STATUS.STOPPED &&
      (!videoStream?.ivs_stream_state || videoStream?.ivs_stream_state === STREAM_STATE.END)
    ) {
      setIsStopLoading(false);
    }
  }, [isStartLoading, isStopLoading, requestStatus, videoStream?.ivs_stream_state]);

  useEffect(() => {
    if (videoStreamError) {
      notification.error({
        message: videoStreamError?.message,
      });
    }
  }, [videoStreamError]);

  useEffect(() => {
    if (requestError) {
      notification.error({
        message: requestError?.message,
      });
    }
  }, [requestError]);

  if (!agent) {
    return null;
  }

  /**
   * 3 States:
   * - Started by the Dashboard (can stop)
   * - Started by an alarm (can't stop)
   * - Started by an agent from the mobile app (can't stop)
   */

  const isAgentOnAlert = ongoingAlarms.some((alarm) => alarm.carrier.id === agent.id);

  const wasStartedByAgent =
    isVideoSteamRunning && !isAgentOnAlert && requestStatus !== STREAM_REQUESTED_STATUS.STARTED;

  const disabledStartAndStopControls = isAgentOnAlert || wasStartedByAgent;

  const isStartDisabled =
    disabledStartAndStopControls ||
    isStopLoading ||
    isVideoSteamRunning ||
    requestStatus === STREAM_REQUESTED_STATUS.STARTED;

  const isStopDisabled =
    disabledStartAndStopControls ||
    isStartLoading ||
    !isVideoSteamRunning ||
    !videoStream?.ivs_stream_state ||
    videoStream?.ivs_stream_state === STREAM_STATE.END;

  const isLoadingOrRunning =
    isAgentOnAlert || isStartLoading || isStopLoading || isVideoSteamRunning;

  const getStartTooltip = () => {
    if (isAgentOnAlert) {
      return i18n.t('carrierDetailsPopup.videoStreaming.startTooltipDisabledHasAlert');
    }
    if (isVideoSteamRunning) {
      return i18n.t('carrierDetailsPopup.videoStreaming.startTooltipDisabledHasStream');
    }
    if (isStartLoading || (isStartDisabled && isStopDisabled)) {
      return i18n.t('common.loading');
    }
    return i18n.t('carrierDetailsPopup.videoStreaming.startTooltip');
  };

  const getStopTooltip = () => {
    if (isAgentOnAlert) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledHasAlert');
    }
    if (wasStartedByAgent) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledStartedByAgent');
    }
    if (isStopLoading || (isStartDisabled && isStopDisabled)) {
      return i18n.t('common.loading');
    }
    if (!isVideoSteamRunning) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledNoStream');
    }
    return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltip');
  };

  const isStartOrRequestStartLoading =
    isStartLoading || requestStatus === STREAM_REQUESTED_STATUS.STARTED;

  const videoPlaceholderText = isStartOrRequestStartLoading
    ? i18n.t('common.loading')
    : i18n.t('carrierDetailsPopup.videoStreaming.offline');

  const mainText = isAgentOnAlert
    ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
    : videoPlaceholderText;

  const popupText = isStartOrRequestStartLoading
    ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
    : i18n.t('carrierDetailsPopup.videoStreaming.videoWillStartStreaming');

  return (
    <div>
      {isVideoSteamRunning && streamUrl ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          key={`${streamUrl}${isStartDisabled}${isStopDisabled}${requestStatus}${videoStream?.ivs_stream_state}`}
          width="100%"
          height={theme.dimensions.videoStreamPlayerHeight}
          ref={videoElement}
          playsInline
          controls
          autoPlay
          data-id="video-stream-player"
          style={{
            backgroundColor: VIDEO_PLAYER_BACKGROUND_COLOR,
            borderRadius: '6px',
            overflow: 'hidden',
          }}
        />
      ) : (
        <VideoWrapperDiv>
          <PositionRelativeDiv data-id="video-stream-placeholder">
            {isPopupVisible && <VideoPopupDiv>{popupText}</VideoPopupDiv>}
            <StyledText
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              style={{
                backgroundColor: isLoadingOrRunning
                  ? VIDEO_PLAYER_BACKGROUND_COLOR
                  : theme.colors.greyBackground,
                color: isLoadingOrRunning ? theme.colors.white : theme.colors.darkBlue,
              }}
            >
              {mainText}
            </StyledText>
          </PositionRelativeDiv>
        </VideoWrapperDiv>
      )}
      {companyFeatures.remoteVideoStreamingControl && (
        <BottomDiv>
          <ActionsDiv>
            <Tooltip title={getStartTooltip()} placement="bottomLeft">
              <Button
                icon={<PlayCircleOutlined data-id="video-play-btn" />}
                loading={isStartLoading}
                disabled={isStartDisabled}
                onClick={async () => {
                  try {
                    if (agent?.id && !isVideoSteamRunning) {
                      setIsStartLoading(true);
                      await requestVideoStreamStatus({
                        variables: {
                          carrierId: agent.id,
                          requestedStatus: STREAM_REQUESTED_STATUS.STARTED,
                        },
                      });
                    }
                  } catch (error) {
                    setIsStartLoading(false);
                    logger.error('VideoStreamPlayer: Failed to start video stream', {
                      error,
                      videoStream,
                    });
                  }
                }}
              >
                {i18n.t('carrierDetailsPopup.videoStreaming.start')}
              </Button>
            </Tooltip>
            <Tooltip title={getStopTooltip()} placement="bottomLeft">
              <Button
                icon={<PauseCircleOutlined data-id="video-pause-btn" />}
                loading={isStopLoading}
                disabled={isStopDisabled}
                onClick={async () => {
                  try {
                    if (agent?.id && isVideoSteamRunning) {
                      setIsStopLoading(true);
                      await requestVideoStreamStatus({
                        variables: {
                          carrierId: agent.id,
                          requestedStatus: STREAM_REQUESTED_STATUS.STOPPED,
                        },
                      });
                    }
                  } catch (error) {
                    setIsStartLoading(false);
                    logger.error('VideoStreamPlayer: Failed to stop video stream', {
                      error,
                      videoStream,
                    });
                  }
                }}
              >
                {i18n.t('carrierDetailsPopup.videoStreaming.stop')}
              </Button>
            </Tooltip>
          </ActionsDiv>
          <div style={{ fontSize: '11px', opacity: 0.75 }}>
            {i18n.t('carrierDetailsPopup.videoStreaming.streaming')}:{' '}
            {isVideoStreamLoading
              ? i18n.t('common.loading')
              : getYesOrNo(videoStream?.ivs_stream_state === STREAM_STATE.START)}
            <br />
            {i18n.t('carrierDetailsPopup.videoStreaming.requested')}:{' '}
            {isRequestLoading
              ? i18n.t('common.loading')
              : getYesOrNo(requestStatus === STREAM_REQUESTED_STATUS.STARTED)}
          </div>
        </BottomDiv>
      )}
    </div>
  );
});

VideoStreamPlayer.displayName = 'VideoStreamPlayer';

export default VideoStreamPlayer;
