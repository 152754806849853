import BellOutlined from '@ant-design/icons/BellOutlined';
import { Badge, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

import { circleButtonStyles } from './NavbarContactsOnCall';

const StyledLink = styled(Link)`
  ${circleButtonStyles}
`;

const NavbarAlarmsLink = memo(() => {
  const { showAlarmsPageFeature } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  if (!showAlarmsPageFeature) {
    return null;
  }

  return (
    <Tooltip title={i18n.t('header.menu.alarms')}>
      <Badge
        count={ongoingAlarms.length}
        offset={[0, 0]}
        style={{ boxShadow: 'none' }}
        size="small"
      >
        <StyledLink to={routes.alarms}>
          <BellOutlined data-id="NavbarAlarmsLink" />
        </StyledLink>
      </Badge>
    </Tooltip>
  );
});

NavbarAlarmsLink.displayName = 'NavbarAlarmsLink';

export default NavbarAlarmsLink;
