// Sensor name

export enum SENSOR_NAME_QUERY {
  connected = 'connected',
  battery = 'battery',
  lte_signal_strength = 'lte_signal_strength',
  gps = 'gps',
  body_multi_sensor_v1 = 'body_multi_sensor_v1',
  /** @deprecated This is an old sensor and it will eventually be removed */
  body_temperature = 'body_temperature', // deprecated
  heart_rate = 'heart_rate',
  system = 'system',
  gas = 'gas',
  radiation = 'radiation',
  driving_type = 'driving_type',
  stress = 'stress',
  proximity_tag = 'proximity_tag',
  brain_stop = 'brain_stop',
}

export enum SENSOR_NAME_VARIABLE {
  connected = 'connected',
  connected_history = 'connected_history',
  battery = 'battery',
  lteSignalStrength = 'lteSignalStrength',
  gps = 'gps',
  bodyMultiSensorV1 = 'bodyMultiSensorV1',
  /** @deprecated This is an old sensor and it will eventually be removed */
  bodyTemperature = 'bodyTemperature', // deprecated
  heartRate = 'heartRate',
  system = 'system',
  gas = 'gas',
  radiation = 'radiation',
  gasAlarm = 'gasAlarm',
  drivingType = 'drivingType',
  stress = 'stress',
  proximityTag = 'proximityTag',
  brainStop = 'brainStop',
}

// Sensor status

export enum SENSOR_STATUS_QUERY {
  connected_status = 'connected#status',
  battery_status = 'battery#status',
  lte_signal_strength_status = 'lte_signal_strength#status',
  gps_status = 'gps#status',
  body_multi_sensor_v1_status = 'body_multi_sensor_v1#status',
  /** @deprecated This is an old sensor and it will eventually be removed */
  body_temperature_status = 'body_temperature#status', // deprecated
  heart_rate_status = 'heart_rate#status',
  system_status = 'system#status',
  gas_status = 'gas#status',
  radiation_status = 'radiation#status',
  driving_type_status = 'driving_type#status',
  stress_status = 'stress#status',
  proximity_tag_status = 'proximity_tag#status',
  emergency_status = 'emergency#status',
  traak_front_status = 'traak_front#status',
  traak_back_status = 'traak_back#status',
}

export enum SENSOR_STATUS_VARIABLE {
  connectedStatus = 'connectedStatus',
  batteryStatus = 'batteryStatus',
  lteSignalStrengthStatus = 'lteSignalStrengthStatus',
  gpsStatus = 'gpsStatus',
  bodyMultiSensorV1Status = 'bodyMultiSensorV1Status',
  /** @deprecated This is an old sensor and it will eventually be removed */
  bodyTemperatureStatus = 'bodyTemperatureStatus', // deprecated
  heartRateStatus = 'heartRateStatus',
  systemStatus = 'systemStatus',
  gasStatus = 'gasStatus',
  radiationStatus = 'radiationStatus',
  drivingTypeStatus = 'drivingTypeStatus',
  stressStatus = 'stressStatus',
  proximityTagStatus = 'proximityTagStatus',
  emergencyStatus = 'emergencyStatus',
  traakFrontStatus = 'traakFrontStatus',
  traakBackStatus = 'traakBackStatus',
}

export interface GpsCoordinates {
  lat: number;
  lng: number;
}

export interface GpsSensorType extends GpsCoordinates {
  sp?: number;
  br?: number;
  acc?: number;
  filt_sp?: number;
  timestamp?: string;
}

export interface PhysiologicalTemperatureSensorType {
  body_temp: number;
  skin_temp: number;
  data_qual: number;
}

export interface BrainStopSensorType {
  stop?: boolean;
}

export interface BatterySensorType {
  value?: {
    battery_level?: number; // From 0 to 100 (if battery_health is 50, a battery_level of 100 means 50% battery level)
    battery_health?: number; // From 0 to 100
    voltage?: number; // Voltage in volts
    is_charging?: number; // 0: not charging, 1: charging
  };
  timestamp?: string;
}

enum CellularNetworkRegistrationStatus {
  NO_REGISTERED_SEARCHING = 0, // No registered searching network
  REGISTERED_HOME = 1, // Registered home network
  NOT_REGISTERED_SEARCHING = 2, // Not registered searching network
  REGISTRATION_DENIED = 3, // Registration denied network
  UNKNOWN = 4, // Unknown network
  ROAMING_REGISTERED = 5, // Roaming registered network
  HOME_SMS_ONLY_REGISTERED = 6, // Home SMS only registered network
  SMS_ONLY_ROAMING_REGISTERED = 7, // SMS only roaming registered network
  ATTACHED_EMERG_SERVICES_ONLY = 8, // Attached emergency service only network
}

export interface LteSignalStrengthSensorType {
  value?: {
    rsrp?: number; // LTE Reference Signal Received Power (RSRP) in dBm
    rsrq?: number; // LTE Reference Signal Received Quality (RSRQ) in dB
    bars?: number; // A number between 0 to 5 (both inclusive) indicating signal strength
    reg?: number | CellularNetworkRegistrationStatus; // PS (Packet Switched) registration status (registered/searching/roaming etc)
  };
  timestamp?: string;
}

// Gas

export enum GAS_SENSOR_TYPE {
  ch4_hc = 'ch4/hc',
  co = 'co',
  co2 = 'co2',
  h2s = 'h2s',
  o2 = 'o2',
}

export enum GAS_SENSOR_ALARM_TYPE {
  ch4_hc_alarm = 'ch4/hc alarm',
  co_alarm = 'co alarm',
  co2_alarm = 'co2 alarm',
  h2s_alarm = 'h2s alarm',
  o2_alarm = 'o2 alarm',
}

export interface GasSensorType {
  [GAS_SENSOR_TYPE.ch4_hc]: number;
  [GAS_SENSOR_TYPE.co]: number;
  [GAS_SENSOR_TYPE.co2]: number;
  [GAS_SENSOR_TYPE.h2s]: number;
  [GAS_SENSOR_TYPE.o2]: number;
}

// Radiation
export interface RadiationSensorType {
  gamma: number;
  background: number;
}
