import { memo } from 'react';

import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';

import SectionDeveloperTools from './components/SectionDeveloperTools';

const TabIntegrations = memo(() => {
  const { isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const { currentCompany } = useCompany();

  const isCompanyAdminOrSuperAdmin = currentCompany?.id && (isSuperAdmin || isCompanyAdmin);

  if (!isCompanyAdminOrSuperAdmin) {
    return null;
  }

  return <SectionDeveloperTools />;
});

TabIntegrations.displayName = 'TabIntegrations';

export default TabIntegrations;
