import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

interface QueryAllBrainsInput {
  nextToken: string | null;
  limit: number;
}

export interface QueryAllBrainsOutput {
  getAllDevices: {
    __typename: 'DeviceConnection';
    nextToken: string | null;
    items: {
      __typename: 'Device';
      name: string;
      carrier: null | {
        __typename: 'Carrier_NonCognito' | 'Carrier_Cognito';
        id: string;
        name: string;
        email: string | null;
        requested_association: string | null; // Device name
        subsidiary: {
          __typename: 'Subsidiary';
          id: string;
          name: string;
          company: {
            __typename: 'Company';
            id: string;
            name: string;
          };
        };
      };
    }[];
  };
}

export default function useQueryAllBrains({
  nextToken,
  limit,
}: {
  nextToken: string | null;
  limit?: number;
}) {
  const { data, loading, error, refetch } = useQuery<QueryAllBrainsOutput, QueryAllBrainsInput>(
    gql`
      query QueryAllBrains($limit: Int, $nextToken: String) {
        getAllDevices(limit: $limit, nextToken: $nextToken) {
          nextToken
          items {
            name
            carrier {
              ... on Carrier_NonCognito {
                id
                name
              }
              ... on Carrier_Cognito {
                id
                name
                email
              }
              requested_association
              subsidiary {
                id
                name
                company {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        limit: limit || 1000,
        nextToken,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  return useMemo(
    () => ({
      allBrains: data?.getAllDevices?.items || [],
      isLoading: loading,
      error,
      refetchAllBrains: refetch,
    }),
    [data?.getAllDevices?.items, loading, error, refetch],
  );
}
