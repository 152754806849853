import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { ALARM_TYPE } from '~/types/alarm';
import { GAS_MEASUREMENT_TYPE } from '~/types/measurements';
import { GAS_SENSOR_TYPE } from '~/types/sensor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

interface Props {
  agent: Agent | undefined;
}

const ContentGasSensors = memo(({ agent }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  if (!agent) {
    return null;
  }

  const gas = agent?.equipmentStatus.gas.healthy ? agent?.sensors?.gas : undefined;

  const hasGasCOAlarm = agentOngoingAlarms.some((alarm) => alarm.type === ALARM_TYPE.gasCO);
  const hasGasCO2Alarm = agentOngoingAlarms.some((alarm) => alarm.type === ALARM_TYPE.gasCO2);
  const hasGasH2SAlarm = agentOngoingAlarms.some((alarm) => alarm.type === ALARM_TYPE.gasH2S);
  const hasGasCH4HCAlarm = agentOngoingAlarms.some((alarm) => alarm.type === ALARM_TYPE.gasCH4HC);
  const hasGasO2Alarm = agentOngoingAlarms.some((alarm) => alarm.type === ALARM_TYPE.gasO2);

  const list = [
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPE.co}`),
      valueText: formatNumberWithUnit({
        number: gas?.[GAS_SENSOR_TYPE.co],
        unit: GAS_MEASUREMENT_TYPE.PPM,
      }),
      style: hasGasCOAlarm ? { color: theme.colors.red } : undefined,
      'data-id': 'gasSensorCO',
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPE.co2}`),
      valueText: formatNumberWithUnit({
        number: gas?.[GAS_SENSOR_TYPE.co2],
        unit: GAS_MEASUREMENT_TYPE.PPM,
      }),
      style: hasGasCO2Alarm ? { color: theme.colors.red } : undefined,
      'data-id': 'gasSensorCO2',
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPE.h2s}`),
      valueText: formatNumberWithUnit({
        number: gas?.[GAS_SENSOR_TYPE.h2s],
        unit: GAS_MEASUREMENT_TYPE.PPM,
      }),
      style: hasGasH2SAlarm ? { color: theme.colors.red } : undefined,
      'data-id': 'gasSensorH2S',
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPE.ch4_hc}`),
      valueText: formatNumberWithUnit({
        number: gas?.[GAS_SENSOR_TYPE.ch4_hc],
        unit: GAS_MEASUREMENT_TYPE.PERCENT_LEL,
      }),
      style: hasGasCH4HCAlarm ? { color: theme.colors.red } : undefined,
      'data-id': 'gasSensorCH4HC',
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPE.o2}`),
      valueText: formatNumberWithUnit({
        number: gas?.[GAS_SENSOR_TYPE.o2],
        unit: GAS_MEASUREMENT_TYPE.PERCENT,
      }),
      style: hasGasO2Alarm ? { color: theme.colors.red } : undefined,
      'data-id': 'gasSensorO2',
    },
  ];

  return <SectionList data-id="SectionGasSensors" elements={list} />;
});

ContentGasSensors.displayName = 'ContentGasSensors';

export default ContentGasSensors;
