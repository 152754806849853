import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Input, Button, Form } from 'antd';
import { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import CompanyFeaturesButtonList from '~/components/CompanyFeaturesButtonList';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';

import OnlyCompanyAdminsAlert from '../OnlyCompanyAdminsAlert';

export const CompanySettingsWrapperDiv = styled.div`
  width: 600px;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

interface FormValues {
  name: string;
}

const TabCompanyDetails = memo(() => {
  const { currentCompany } = useCompany();
  const [form] = Form.useForm<FormValues>();

  const { companyFeatures } = useCompanyFeatures();

  const isLoading = false;

  useEffect(() => {
    form.resetFields();
  }, [form, currentCompany?.id]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFinish = useCallback(async (values: FormValues) => {
    try {
      // TODO: Update company
      // eslint-disable-next-line no-console
      notification.success({
        message: i18n.t('common.success'),
      });
    } catch (error) {
      notification.error({
        message: (error as Error)?.message,
      });
    }
  }, []);

  if (!currentCompany?.id) {
    return <p>{i18n.t('companySettingsPage.companyTab.missingCompany')}</p>;
  }

  return (
    <CompanySettingsWrapperDiv>
      <OnlyCompanyAdminsAlert />
      <Form
        style={{ marginTop: '20px' }}
        form={form}
        onFinish={onFinish}
        initialValues={{
          name: currentCompany?.name || '',
        }}
        layout="vertical"
      >
        <Form.Item label={i18n.t('companySettingsPage.companyTab.companyName')} name="name">
          <Input type="text" required disabled />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            disabled
            icon={<SaveOutlined />}
          >
            {i18n.t('common.save')}
          </Button>
        </Form.Item>
      </Form>
      <TabSectionHeader title={i18n.t('companySettingsPage.companyTab.companyFeatures')} />
      <CompanyFeaturesButtonList
        companyFeaturesToShow={{
          safeZone: companyFeatures.safeZone,
          issueTracking: companyFeatures.issueTracking,
        }}
      />
    </CompanySettingsWrapperDiv>
  );
});

TabCompanyDetails.displayName = 'TabCompanyDetails';

export default TabCompanyDetails;
