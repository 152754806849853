import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Button, Input, Space, Popover, type InputRef, type TableProps } from 'antd';
import type { CompareFn } from 'antd/es/table/interface';
import type {
  ColumnType,
  FilterConfirmProps,
  FilterDropdownProps,
  SorterResult,
  SortOrder,
} from 'antd/lib/table/interface';
import Table from 'antd/lib/table/Table';
import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  type RefObject,
  type Key,
  type CSSProperties,
} from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import { SORT_ORDER } from '~/types/table';
import browserStorage from '~/utils/browserStorage';

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

export interface SortableSearchableColumn {
  key: string;
  dataIndex: string;
  searchIndex: string;
  title: string;
  popover?: string;
  filtered?: boolean;
  hasFilterHighlight?: boolean;
  sorter?: CompareFn<Record<string, unknown>>;
  defaultSortOrder?: SORT_ORDER;
  onCell?: (record: Record<string, unknown>) => { style: CSSProperties };
}

type OnInputChangeType = (
  searchIndex: string,
  value: string,
  confirm: (param?: FilterConfirmProps) => void,
  setSelectedKeys: (selectedKeys: Key[]) => void,
) => void;

type OnResetType = (
  confirm?: (param?: FilterConfirmProps) => void,
  clearFilters?: () => void,
) => void;

function getColumnSearchProps({
  column,
  filter,
  inputRef,
  onInputChange,
  onReset,
}: {
  column: SortableSearchableColumn;
  filter?: string[];
  inputRef: RefObject<InputRef>;
  onInputChange: OnInputChangeType;
  onReset: OnResetType;
}): ColumnType<Record<string, string>> {
  return {
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} data-id={`table-column-search-${column.searchIndex}`}>
        <Space>
          <Input
            ref={inputRef}
            placeholder={`Search ${column.searchIndex}`}
            value={column.searchIndex === filter?.[0] ? filter?.[1] : ''}
            onChange={(e) =>
              onInputChange(column.searchIndex, e.target.value, confirm, setSelectedKeys)
            }
          />
          <Button
            onClick={() => onReset(confirm, clearFilters)}
            size="middle"
            style={{ width: 90 }}
          >
            {i18n.t('common.clear')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={filtered ? { color: '#1890ff' } : {}} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.current?.select(), 50);
      }
    },
    render: (text, record) => {
      const content =
        column.hasFilterHighlight && filter?.[0] === column.searchIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[filter?.[1] || '']}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        );
      return column.popover && record[column.popover] ? (
        <Popover content={record[column.popover]}>
          <span style={{ display: 'inline-block', cursor: 'help' }}>{content}</span>
        </Popover>
      ) : (
        content
      );
    },
  };
}

const sortStorageKey = 'sortableSearchableTableSort';
const filterStorageKey = 'sortableSearchableTableFilter';

type Props = TableProps<any> &
  Omit<TableProps<Record<string, unknown>>, 'id'> & {
    id: string;
    'data-id'?: string;
  };

/** @deprecated We should rewrite this component because the TypeScript types are not correct */
const SortableSearchableTable = memo(
  ({ id, columns, dataSource, pagination, loading, 'data-id': dataId }: Props) => {
    const { currentUser } = useCurrentUserContext();
    const { hasAlert } = useAlarmsContext();
    const [sort, setSort] = useState(['', '']);
    const [filter, setFilter] = useState(['', '']);
    const inputRef = useRef<InputRef>(null);

    const handleChange = useCallback(
      (
        _pagination: unknown,
        filters: unknown,
        sorter: SorterResult<object> | SorterResult<object>[],
        extra: { action: 'paginate' | 'sort' | 'filter' },
      ) => {
        if (extra?.action === 'sort' && currentUser?.id) {
          const savedSort = browserStorage.session.get(sortStorageKey, true) || {};
          savedSort[currentUser.id] = {
            ...savedSort[currentUser.id],
            [id]: [
              (sorter as SorterResult<object>).columnKey,
              (sorter as SorterResult<object>).order,
            ],
          };
          browserStorage.session.set(sortStorageKey, savedSort, true);
          setSort(savedSort[currentUser.id][id]);
        }
      },
      [id, currentUser?.id],
    );

    const handleSearch = useCallback(
      (column = '', searchTerm = '') => {
        if (!currentUser?.id) {
          return;
        }
        const savedFilter = browserStorage.session.get(filterStorageKey, true) || {};
        savedFilter[currentUser.id] = {
          ...savedFilter[currentUser.id],
          [id]: [column, searchTerm],
        };
        browserStorage.session.set(filterStorageKey, savedFilter, true);
        setFilter(savedFilter[currentUser.id][id]);
      },
      [id, currentUser?.id],
    );

    const onReset: OnResetType = useCallback(
      (confirm, clearFilters) => {
        handleSearch('', '');
        clearFilters?.();
        confirm?.({ closeDropdown: false });
      },
      [handleSearch],
    );

    const onInputChange: OnInputChangeType = useCallback(
      (searchIndex, value, confirm, setSelectedKeys) => {
        handleSearch(searchIndex, value);
        setSelectedKeys(value ? [value] : []);
        confirm({ closeDropdown: false });
      },
      [handleSearch],
    );

    const parsedColumns = useMemo(
      () =>
        columns?.map((column: any) => ({
          ...column,
          ...(column.sorter && sort?.[0] === column.searchIndex
            ? { sortOrder: sort[1] as SortOrder }
            : {}),
          ...(column.filtered
            ? getColumnSearchProps({
                column,
                filter,
                inputRef,
                onInputChange,
                onReset,
              })
            : {}),
        })),
      [columns, filter, onInputChange, onReset, sort],
    );

    const parsedDataSource = useMemo(
      () =>
        dataSource?.filter((record) =>
          filter?.[0]
            ? record[filter[0] as keyof typeof record]
                ?.toString()
                ?.toLowerCase()
                ?.includes(filter[1].toLowerCase())
            : true,
        ),
      [dataSource, filter],
    );

    useEffect(() => {
      if (!currentUser?.id) {
        return;
      }
      setSort(browserStorage.session.get(sortStorageKey, true)?.[currentUser.id]?.[id]);
      setFilter(browserStorage.session.get(filterStorageKey, true)?.[currentUser.id]?.[id]);
    }, [currentUser?.id, id]);

    useEffect(() => {
      if (hasAlert) {
        onReset();
      }
    }, [hasAlert, onReset]);

    return (
      <StyledTable
        tableLayout="auto"
        pagination={pagination}
        loading={loading}
        columns={parsedColumns}
        dataSource={parsedDataSource}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={handleChange}
        data-id={dataId}
      />
    );
  },
);

SortableSearchableTable.displayName = 'SortableSearchableTable';

export default SortableSearchableTable;
