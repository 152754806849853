import styled from 'styled-components';

import theme from '~/theme';

// Settings list page

export const SettingsGridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 310px) minmax(0, 1fr);
  grid-gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const SettingsStickyDiv = styled.div`
  position: sticky;
  top: calc(${theme.dimensions.navbarHeight}px + 16px);

  ${theme.medias.lteSmall} {
    position: initial;
    top: 0;
  }
`;

export const SettingsTabTitleH3 = styled.h3`
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 8px;
`;

export const SettingsTabContentDiv = styled.div`
  padding-bottom: 64px;
`;

// Settings content page

export const SelectCompanySubsidiaryDiv = styled.div`
  display: flex;
  gap: 16px;

  ${theme.medias.lteMedium} {
    display: block;
    margin-bottom: 8px;
  }
`;

export const SettingsTopFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.medias.extraSmall} {
    display: block;
    margin-bottom: 16px;
  }
`;

export const SettingsButtonsFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
