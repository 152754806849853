import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';

import useMutationDeleteAdmin from '~/apollo/hooks/admins/useMutationDeleteAdmin';
import useQueryCompanyAdmins from '~/apollo/hooks/admins/useQueryCompanyAdmins';
import useQuerySubsidiaryAdmins from '~/apollo/hooks/admins/useQuerySubsidiaryAdmins';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SelectCompanySubsidiaryDiv,
  SettingsButtonsFlexDiv,
  SettingsTopFlexDiv,
} from '~/components/settings/shared';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import { TextAvatarDiv } from '~/pages/BackofficePage/components/TabSuperAdmins/TabSuperAdmins';
import theme from '~/theme';
import { USER_TYPE } from '~/types/user';
import notification from '~/utils/notification';
import sortTableColumnWithEmptyValues from '~/utils/table/sortTableColumnWithEmptyValues';

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  role: string;
  subsidiary: string | null;
  actions: ReactNode;
}

interface Props {
  showCompanySelect: boolean;
}

const TabAdmins = memo(({ showCompanySelect }: Props) => {
  const {
    isSubsidiaryAdmin,
    isLoading: isLoadingCurrentUser,
    currentUser,
  } = useCurrentUserContext();
  const { openModal } = useModalsContext();
  const { currentCompany } = useCompany();
  const { currentSubsidiary } = useSubsidiary();

  const {
    isLoading: isCompanyAdminsLoading,
    admins: companyAdmins,
    refetchCompanyAdmins,
  } = useQueryCompanyAdmins({ companyId: isSubsidiaryAdmin ? undefined : currentCompany?.id });

  const {
    isLoading: isSubsidiaryAdminsLoading,
    admins: subsidiaryAdmins,
    refetchSubsidiaryAdmins,
  } = useQuerySubsidiaryAdmins({
    subsidiaryId: isSubsidiaryAdmin ? currentSubsidiary?.id : undefined,
    nextToken: null,
  });

  const { deleteAdmin, isLoading: isDeleteAdminLoading } = useMutationDeleteAdmin();

  const admins = isSubsidiaryAdmin ? subsidiaryAdmins : companyAdmins;

  const isLoading =
    isLoadingCurrentUser ||
    isCompanyAdminsLoading ||
    isSubsidiaryAdminsLoading ||
    isDeleteAdminLoading;

  const totalRefetch = useCallback(async () => {
    await Promise.all(isSubsidiaryAdmin ? [refetchSubsidiaryAdmins()] : [refetchCompanyAdmins()]);
  }, [isSubsidiaryAdmin, refetchCompanyAdmins, refetchSubsidiaryAdmins]);

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'email',
        title: i18n.t('common.email'),
        sorter: (a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
        defaultSortOrder: 'ascend',
        render: (text, record) => {
          const fullName = [record.firstName, record.lastName].filter(Boolean).join(' ');
          return (
            <TextAvatarDiv>
              <ReactAvatar name={fullName || text} round size="26px" /> {text}
              {currentUser?.email === text && <Tag color="green">{i18n.t('common.you')}</Tag>}
            </TextAvatarDiv>
          );
        },
      },
      {
        dataIndex: 'role',
        title: i18n.t('common.role'),
        sorter: (a, b) => a.role.toLowerCase().localeCompare(b.role.toLowerCase()),
        render: (text) => (
          <Tag color={text === USER_TYPE.CompanyAdmin ? 'blue' : 'default'}>
            {i18n.t(`roles.${text}`)}
          </Tag>
        ),
      },
      {
        dataIndex: 'subsidiary',
        title: i18n.t('common.subsidiary'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.subsidiary, b.subsidiary, sortOrder),
        render: (text) => text || '-',
      },
      {
        dataIndex: 'firstName',
        title: i18n.t('common.firstName'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.firstName, b.firstName, sortOrder),
        render: (text) => text || '-',
      },
      {
        dataIndex: 'lastName',
        title: i18n.t('common.lastName'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.lastName, b.lastName, sortOrder),
        render: (text) => text || '-',
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
      },
    ],
    [currentUser?.email],
  );

  const data: TableDataType[] = useMemo(
    () =>
      admins.map((admin) => {
        const canDelete = currentUser?.email !== admin.email;

        return {
          key: admin.id,
          id: admin.id,
          email: admin.email,
          firstName: admin.first_name || null,
          lastName: admin.last_name || null,
          role: admin.__typename,
          subsidiary: admin.subsidiaryName || null,
          actions: (
            <ActionsDiv>
              <Tooltip
                title={
                  canDelete
                    ? i18n.t('companySettingsPage.adminsTab.deleteAdmin')
                    : i18n.t('companySettingsPage.adminsTab.cannotDeleteYourself')
                }
              >
                <Button
                  danger
                  disabled={!canDelete || isLoading}
                  onClick={() => {
                    openModal({
                      type: 'confirmation',
                      title: i18n.t('companySettingsPage.adminsTab.deleteAdmin'),
                      description: (
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i18n.t<string>(
                              'companySettingsPage.adminsTab.areYouSureYouWantToDeleteAdminHtml',
                              {
                                email: admin.email,
                              },
                            ),
                          }}
                        />
                      ),
                      actionType: 'danger',
                      action: async () => {
                        try {
                          await deleteAdmin({
                            variables: {
                              adminId: admin.id,
                            },
                          });
                          await totalRefetch();
                          notification.success({
                            message: i18n.t('common.success'),
                            description: i18n.t(
                              'companySettingsPage.adminsTab.adminDeletedSuccess',
                            ),
                          });
                        } catch (error) {
                          notification.error({
                            message: (error as any)?.message || i18n.t('common.error'),
                          });
                        }
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </ActionsDiv>
          ),
        };
      }),
    [admins, currentUser?.email, isLoading, openModal, deleteAdmin, totalRefetch],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv>
        {showCompanySelect && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
            <SettingsSelectCompany isLoading={isLoading} />
          </div>
        )}
      </SelectCompanySubsidiaryDiv>
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('companySettingsPage.adminsTab.listOfAdmins')} (${admins.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              openModal({
                type: 'adminAddEdit',
                initialValues: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
          >
            {i18n.t('companySettingsPage.adminsTab.createAdmin')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} admins`,
          hideOnSinglePage: true,
        }}
      />
    </WrapperDiv>
  );
});

TabAdmins.displayName = 'TabAdmins';

export default TabAdmins;
