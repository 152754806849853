import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

const RADIATION_UNIT = 'μSv/h';
const RADIATION_DECIMALS = 3;

interface Props {
  agent: Agent | undefined;
}

const ContentRadiation = memo(({ agent }: Props) => {
  const radiation = agent?.equipmentStatus.radiation.healthy
    ? agent?.sensors?.radiation
    : undefined;

  const list = useMemo(
    () => [
      {
        labelText: i18n.t('carrierDetailsPopup.radiation.gamma'),
        valueText: formatNumberWithUnit({
          number: radiation?.gamma,
          unit: RADIATION_UNIT,
          decimals: RADIATION_DECIMALS,
        }),
        style: undefined,
      },
      {
        labelText: i18n.t('carrierDetailsPopup.radiation.background'),
        valueText: formatNumberWithUnit({
          number: radiation?.background,
          unit: RADIATION_UNIT,
          decimals: RADIATION_DECIMALS,
        }),
        style: undefined,
      },
    ],
    [radiation?.gamma, radiation?.background],
  );

  if (!agent) {
    return null;
  }

  return <SectionList elements={list} />;
});

ContentRadiation.displayName = 'ContentRadiation';

export default ContentRadiation;
