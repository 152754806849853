import { Button } from 'antd';
import { memo, useEffect } from 'react';
import styled from 'styled-components';

import useMutationDeleteAgent from '~/apollo/hooks/agent/useMutationDeleteAgent';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import notification from '~/utils/notification';

const ActionsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  agent: Agent | undefined;
  onClose: () => void;
}

const AgentDeleteForm = memo(({ agent, onClose }: Props) => {
  const { deleteAgent, loading, error, deletedAgent } = useMutationDeleteAgent();

  const name = agent?.completeName
    ? `${agent?.completeName} (${agent?.attributes?.acronym})`
    : agent?.id;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (deletedAgent) {
      notification.success({
        message: i18n.t('agentForm.deleteAgentSuccess', { name }),
      });
      onClose();
    } else if (error) {
      notification.error({
        message: error.message,
      });
    }
  }, [name, loading, error, deletedAgent, onClose]);

  if (!agent?.id) {
    return null;
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        deleteAgent({ variables: { agentId: agent.id } });
      }}
      data-id="delete-agent-modal"
    >
      <p>
        {i18n.t('agentForm.deleteAgentConfirmation')}
        <br />
        <b>{name}</b>
      </p>
      <ActionsDiv>
        <Button type="default" onClick={onClose} disabled={loading} data-id="close-btn">
          {i18n.t('common.no')}
        </Button>
        <Button htmlType="submit" type="primary" danger loading={loading}>
          {i18n.t('common.yes')}
        </Button>
      </ActionsDiv>
    </form>
  );
});

AgentDeleteForm.displayName = 'AgentDeleteForm';

export default AgentDeleteForm;
