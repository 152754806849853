import { memo } from 'react';

import SubsidiaryAddEditForm from '~/components/forms/SubsidiaryAddEditForm';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';

import { CompanySettingsWrapperDiv } from '../TabCompanyDetails/TabCompanyDetails';

const TabSubsidiaryDetails = memo(() => {
  const { currentSubsidiary } = useSubsidiary();

  if (!currentSubsidiary?.id) {
    return <p>{i18n.t('subsidiaryDetails.missingSubsidiary')}</p>;
  }

  return (
    <CompanySettingsWrapperDiv>
      <SubsidiaryAddEditForm
        subsidiaryId={currentSubsidiary?.id}
        refetchOnCompleted={async () => undefined}
        onClose={async () => undefined}
        showCancel={false}
      />
    </CompanySettingsWrapperDiv>
  );
});

TabSubsidiaryDetails.displayName = 'TabSubsidiaryDetails';

export default TabSubsidiaryDetails;
