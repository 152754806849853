import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Input, Button, Form } from 'antd';
import { memo, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import useMutationCreateSubsidiary from '~/apollo/hooks/subsidiary/useMutationCreateSubsidiary';
import useMutationUpdateSubsidiary from '~/apollo/hooks/subsidiary/useMutationUpdateSubsidiary';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import { DEFAULT_GPS_POSITION } from '~/config/defaults';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';
import isValidCoordinate from '~/utils/validation/isValidCoordinate';

const CoordinatesDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 8px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const BottomFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function coordinateValidator(value: string, coordinate: Parameters<typeof isValidCoordinate>[1]) {
  return isValidCoordinate(parseFloat(value), coordinate)
    ? Promise.resolve()
    : Promise.reject(new Error());
}

interface FormValues {
  name: string;
  latitude: string;
  longitude: string;
}

interface Props {
  subsidiaryId: string | undefined;
  refetchOnCompleted: () => Promise<void>;
  onClose: () => void;
  showCancel: boolean;
}

const SubsidiaryAddEditForm = memo(
  ({ subsidiaryId, refetchOnCompleted, onClose, showCancel }: Props) => {
    const { isSuperAdmin, isLoading: isCurrentUserLoading } = useCurrentUserContext();
    const { currentCompany } = useCompany();
    const { currentSubsidiary, subsidiaryList } = useSubsidiary();
    const { createSubsidiary, isLoading: isCreateLoading } = useMutationCreateSubsidiary();
    const { updateSubsidiary, isLoading: isUpdateLoading } = useMutationUpdateSubsidiary();

    const isLoading = isCurrentUserLoading || isCreateLoading || isUpdateLoading;

    const isEdit = !!subsidiaryId;

    const subsidiary = useMemo(() => {
      if (currentSubsidiary?.id === subsidiaryId) {
        return currentSubsidiary;
      }
      return subsidiaryList.find(({ id }) => id === subsidiaryId);
    }, [currentSubsidiary, subsidiaryId, subsidiaryList]);

    const [form] = Form.useForm<FormValues>();

    useEffect(() => {
      form.resetFields();
    }, [form, subsidiary?.id]);

    const onFinish = useCallback(
      async (values: FormValues) => {
        try {
          if (!currentCompany?.id) {
            return;
          }
          const location = {
            lat: parseFloat(values.latitude),
            lng: parseFloat(values.longitude),
          };
          if (subsidiary) {
            await updateSubsidiary({
              subsidiaryId: subsidiary.id,
              name: values.name,
              location,
            });
          } else {
            await createSubsidiary({
              variables: {
                company_id: currentCompany?.id,
                name: values.name,
                location,
              },
            });
          }
          onClose();
          notification.success({
            message: subsidiary
              ? i18n.t('subsidiaryDetails.updateSuccessful')
              : i18n.t('subsidiaryDetails.createSuccessful'),
          });
          await refetchOnCompleted?.();
        } catch (error) {
          notification.error({
            message: (error as Error)?.message,
          });
        }
      },
      [
        createSubsidiary,
        currentCompany?.id,
        onClose,
        refetchOnCompleted,
        subsidiary,
        updateSubsidiary,
      ],
    );

    return (
      <Form
        style={{ marginTop: '20px' }}
        form={form}
        onFinish={onFinish}
        initialValues={{
          name: subsidiary?.name || '',
          latitude: subsidiary?.location?.lat?.toString() || '',
          longitude: subsidiary?.location?.lng?.toString() || '',
        }}
        layout="vertical"
      >
        {isSuperAdmin && (
          <Form.Item label={i18n.t('common.company')}>
            <SettingsSelectCompany disabled={!isSuperAdmin || isEdit} isLoading={isLoading} />
          </Form.Item>
        )}
        <Form.Item
          label={i18n.t('subsidiaryDetails.subsidiaryName')}
          name="name"
          rules={[{ message: i18n.t<string>('subsidiaryDetails.errorMessages.name') }]}
        >
          <Input
            type="text"
            placeholder={i18n.t<string>('subsidiaryDetails.subsidiaryName')}
            required
            disabled={isLoading}
          />
        </Form.Item>
        <CoordinatesDiv>
          <Form.Item
            label={i18n.t('subsidiaryDetails.subsidiaryMapCoordinates')}
            name="latitude"
            rules={[
              {
                message: i18n.t<string>('subsidiaryDetails.errorMessages.latitude'),
                validator: (rule, value: FormValues['latitude']) =>
                  coordinateValidator(value, 'latitude'),
              },
            ]}
          >
            <Input
              placeholder={DEFAULT_GPS_POSITION.lat.toString()}
              type="text"
              addonBefore={i18n.t('subsidiaryDetails.latitude')}
              required
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item
            label=" "
            name="longitude"
            rules={[
              {
                message: i18n.t<string>('subsidiaryDetails.errorMessages.longitude'),
                validator: (rule, value: FormValues['longitude']) =>
                  coordinateValidator(value, 'longitude'),
              },
            ]}
          >
            <Input
              placeholder={DEFAULT_GPS_POSITION.lng.toString()}
              type="text"
              addonBefore={i18n.t('subsidiaryDetails.longitude')}
              required
              disabled={isLoading}
            />
          </Form.Item>
        </CoordinatesDiv>
        <BottomFlexDiv>
          {showCancel && (
            <Button size="middle" disabled={isLoading} onClick={onClose}>
              {i18n.t('common.cancel')}
            </Button>
          )}
          <Button
            size="middle"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={<SaveOutlined />}
          >
            {i18n.t('common.save')}
          </Button>
        </BottomFlexDiv>
      </Form>
    );
  },
);

SubsidiaryAddEditForm.displayName = 'SubsidiaryAddEditForm';

export default SubsidiaryAddEditForm;
