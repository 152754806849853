import { gql, useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

interface AssociateBrainToAgentInput {
  carrier_id: string;
  device_name: string;
}

interface AssociateBrainToAgentOutput {
  associateCarrierDevice: {
    id: string;
  };
}

export default function useMutationAssociateBrainToAgent(): {
  associateBrainToAgent: (params: { variables: AssociateBrainToAgentInput }) => Promise<unknown>;
  isAssociateBrainToAgentLoading: boolean;
  error?: ApolloError;
} {
  const [associateBrainToAgent, { loading, error }] = useMutation<
    AssociateBrainToAgentOutput,
    AssociateBrainToAgentInput
  >(
    gql`
      mutation MutationAssociateBrainToAgent($carrier_id: ID!, $device_name: String!) {
        associateCarrierDevice(carrier_id: $carrier_id, device_name: $device_name) {
          id
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      associateBrainToAgent,
      isAssociateBrainToAgentLoading: loading,
      error,
    }),
    [associateBrainToAgent, error, loading],
  );
}
