import { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import DetailsPopup from '~/components/DetailsPopup';
import MainMap from '~/components/maps/MainMap';
import RealtimeLocationDisabledBanner from '~/components/RealtimeLocationDisabledBanner';
import {
  CARRIER_MAP_ZOOM_LEVEL,
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import useAgentsContext from '~/context/useAgentsContext';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useInitialLoading from '~/hooks/useInitialLoading';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTeams from '~/hooks/useTeams';
import useMapSettings from '~/store/useMapSettings';
import theme from '~/theme';
import type { GpsCoordinates } from '~/types/sensor';

const AbsoluteTopDiv = styled.div`
  position: absolute;
  top: ${theme.dimensions.navbarHeight}px;
  left: calc(8px + ${theme.dimensions.detailsPopupWidth}px);
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  ${theme.medias.lteSmall} {
    display: none;
  }
`;

const StatusPage = memo(() => {
  const query = useQueryParams();

  const { resetAgentStatuses } = useAgentStatuses();
  const { setSelectedTeams } = useTeams();
  const { isInitialLoading } = useInitialLoading();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const { showRealtimeLocationFeature } = useCompanyFeatures();

  usePreventPageScroll();

  const agent = useMemo(() => getAgent(query?.id ?? ''), [getAgent, query?.id]);

  const vehicle = useMemo(
    () => (agent?.attributes?.plate_number ? getVehicle(agent.attributes.plate_number) : undefined),
    [agent?.attributes?.plate_number, getVehicle],
  );

  const showRealtimeLocation = showRealtimeLocationFeature(agent);

  const centerPosition = useMemo(() => {
    const defaultLocation = currentSubsidiary?.location || DEFAULT_GPS_POSITION;

    return showRealtimeLocation
      ? vehicle?.location || agent?.sensors.gps || defaultLocation
      : defaultLocation;
  }, [currentSubsidiary?.location, showRealtimeLocation, vehicle?.location, agent?.sensors.gps]);

  const computedRightSidePosition: GpsCoordinates = useMemo(
    () => ({ lat: centerPosition.lat, lng: centerPosition.lng - 0.03 }),
    [centerPosition],
  );

  const zoom = useMemo(
    () =>
      centerPosition !== DEFAULT_GPS_POSITION ? CARRIER_MAP_ZOOM_LEVEL : DEFAULT_MAP_ZOOM_LEVEL,
    [centerPosition],
  );

  const shouldFollowCarrier = useMapSettings((state) => state.shouldFollowCarrier);

  useEffect(() => {
    setSelectedTeams([]);
    resetAgentStatuses();
  }, [resetAgentStatuses, setSelectedTeams]);

  if (!query?.id) {
    return null;
  }

  return (
    <>
      <MainMap
        key={query.id}
        isLoading={isInitialLoading}
        isBlurred={!showRealtimeLocation}
        initialZoom={zoom}
        initialLat={computedRightSidePosition.lat}
        initialLng={computedRightSidePosition.lng}
        followingZoom={shouldFollowCarrier ? zoom : undefined}
        followingLat={shouldFollowCarrier ? computedRightSidePosition.lat : undefined}
        followingLng={shouldFollowCarrier ? computedRightSidePosition.lng : undefined}
      />
      <DetailsPopup queryAgentId={query.id} />
      {!isInitialLoading && !showRealtimeLocation && (
        <AbsoluteTopDiv>
          <RealtimeLocationDisabledBanner />
        </AbsoluteTopDiv>
      )}
    </>
  );
});

StatusPage.displayName = 'StatusPage';

export default StatusPage;
