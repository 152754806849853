import ApiOutlined from '@ant-design/icons/ApiOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { AutoComplete, Button, Form, Input, Select } from 'antd';
import { orderBy } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useMutationAssociateBrainToAgent from '~/apollo/hooks/brain/useMutationAssociateBrainToAgent';
import type { QueryAllBrainsOutput } from '~/apollo/hooks/brain/useQueryAllBrains';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import useAgentsContext from '~/context/useAgentsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import notification from '~/utils/notification';

const BottomFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface FormValues {
  deviceName: string;
  agentId: string;
}

export interface Props {
  allBrains: QueryAllBrainsOutput['getAllDevices']['items'];
  initialValues?: Partial<FormValues>;
  onSuccess: () => Promise<void>;
  onClose?: () => void;
}

const BrainAssociateToAgentForm = memo(
  ({ allBrains, initialValues, onSuccess, onClose }: Props) => {
    const { currentCompany } = useCompany();
    const { currentSubsidiary } = useSubsidiary();

    const {
      agents,
      isInitialLoading: isAgentsInitialLoading,
      isLoading: isAgentsLoading,
    } = useAgentsContext();

    const { associateBrainToAgent, isAssociateBrainToAgentLoading } =
      useMutationAssociateBrainToAgent();

    const isLoading = isAgentsInitialLoading || isAgentsLoading || isAssociateBrainToAgentLoading;

    const [form] = Form.useForm<FormValues>();

    const deviceName = Form.useWatch('deviceName', form);
    const agentId = Form.useWatch('agentId', form);

    useEffect(() => {
      form.setFieldValue('agentId', undefined);
    }, [currentCompany?.id, currentSubsidiary?.id, form]);

    const [autoCompleteText, setAutoCompleteText] = useState<string>(deviceName || '');

    const agentOptions = useMemo(
      () =>
        orderBy(
          agents.map((agent) => {
            const label = `${agent.name} (${agent.email})`;
            return {
              value: agent.id,
              label,
              labelNormalized: label.toLocaleLowerCase(),
              disabled: !!agent.deviceName,
            };
          }),
          ['labelNormalized'],
          ['asc'],
        ),
      [agents],
    );

    const brainOptions = useMemo(
      () =>
        orderBy(
          allBrains
            .filter((brain) => brain.name.toLowerCase().includes(autoCompleteText))
            .map((brain) => ({
              label: brain.name,
              value: brain.name,
              labelLowerCase: brain.name.toLowerCase(),
              disabled: !!brain.carrier?.id,
            })),
          ['labelLowerCase'],
          ['asc'],
        ),
      [allBrains, autoCompleteText],
    );

    const hasDeviceError = useMemo(() => {
      if (!deviceName) {
        return false;
      }
      const foundBrain = allBrains.find((d) => d.name === deviceName);
      return !foundBrain || !!foundBrain.carrier?.id;
    }, [allBrains, deviceName]);

    const handleSubmit = useCallback(
      async (formValues: FormValues) => {
        try {
          await associateBrainToAgent({
            variables: {
              device_name: formValues.deviceName,
              carrier_id: formValues.agentId,
            },
          });
          notification.success({
            message: i18n.t('common.success'),
            description: i18n.t('backofficePage.brainsTab.brainAssociatedSuccessfully'),
            duration: 10,
          });
          onClose?.();
          await onSuccess();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          notification.error({
            message: i18n.t('common.error'),
            description: (error as any)?.message,
          });
        }
      },
      [associateBrainToAgent, onClose, onSuccess],
    );

    return (
      <Form
        form={form}
        layout="vertical"
        validateTrigger="onBlur"
        initialValues={{
          ...initialValues,
          companyId: currentCompany?.id,
          subsidiaryId: currentSubsidiary?.id,
        }}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <div
          style={{
            fontStyle: 'italic',
            marginBottom: '12px',
          }}
        >
          <WarningOutlined /> {i18n.t('backofficePage.brainsTab.disassociateBrainWarningMessage')}
        </div>
        <Form.Item name="deviceName" label={i18n.t('common.brain')}>
          <AutoComplete
            options={brainOptions}
            onChange={(value) => {
              setAutoCompleteText(value?.trim()?.toLowerCase() || '');
            }}
            placeholder={i18n.t<string>('common.brain')}
            disabled={isLoading}
            showAction={['focus']}
            onBlur={() => {
              setAutoCompleteText('');
            }}
          >
            <Input
              type="text"
              required
              style={{ borderColor: hasDeviceError ? 'red' : undefined }}
            />
          </AutoComplete>
        </Form.Item>
        <Form.Item label={i18n.t('common.company')}>
          <SettingsSelectCompany disabled={isLoading} />
        </Form.Item>
        <Form.Item label={i18n.t('common.subsidiary')}>
          <SettingsSelectSubsidiary disabled={isLoading || !currentCompany?.id} />
        </Form.Item>
        <Form.Item name="agentId" label={i18n.t('common.agent')}>
          <Select
            disabled={isLoading || !currentCompany?.id || !currentSubsidiary?.id}
            options={agentOptions}
            placeholder={i18n.t<string>('common.agent')}
            onChange={(value) => {
              form.setFieldValue('agentId', value);
            }}
          />
        </Form.Item>
        <BottomFlexDiv>
          <Button size="middle" disabled={isLoading} onClick={onClose}>
            {i18n.t('common.cancel')}
          </Button>
          <Button
            size="middle"
            type="primary"
            htmlType="submit"
            disabled={
              hasDeviceError ||
              !deviceName ||
              !currentCompany?.id ||
              !currentSubsidiary?.id ||
              !agentId
            }
            loading={isLoading}
            icon={<ApiOutlined />}
          >
            {i18n.t('backofficePage.brainsTab.associateBrain')}
          </Button>
        </BottomFlexDiv>
      </Form>
    );
  },
);

BrainAssociateToAgentForm.displayName = 'BrainAssociateToAgentForm';

export default BrainAssociateToAgentForm;
